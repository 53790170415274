import {
  SaveOutlined,
  ClearOutlined,
  UnorderedListOutlined,
  MailOutlined,
  SendOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Tag,
} from "antd";
import moment from "moment-timezone";
import React, { useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const SmsNotifications = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [formValues, setFormValues] = useState(null);

  const onFinish = async (values) => {
    console.log(values);
    values = { ...values, date: moment(values.date).format() };
    setFormValues(values);
    setLoading(true);
    const responses = await Promise.all([
      AdminService.getShortUrl(values.weblink),
      AdminService.getShortUrl(values.zoomlink),
    ]);
    console.log(responses);

    console.log("final");

    setLoading(false);
    const zamanlar = [];
    const linkim =
      responses[0].data !== ""
        ? "Details: " + responses[0].data
        : responses[1].data !== ""
        ? "Zoom Link: " + responses[1].data
        : "";
    values.times.map((time) => {
      switch (time) {
        case 1: //7 days ago
          zamanlar.push({
            time: moment(values.date)
              .set("hour", 18)
              .set("minute", 0)
              .set("second", 0)
              .subtract(7, "d")
              .tz("Australia/Sydney")
              .format(),
            message: values.name + " will start in 7 days. " + linkim,
          });
          break;
        case 2: //5 days ago
          zamanlar.push({
            time: moment(values.date)
              .set("hour", 18)
              .set("minute", 0)
              .set("second", 0)
              .subtract(5, "d")
              .tz("Australia/Sydney")
              .format(),
            message: values.name + " will start in 5 days. " + linkim,
          });
          break;
        case 3: //3 days ago
          zamanlar.push({
            time: moment(values.date)
              .set("hour", 18)
              .set("minute", 0)
              .set("second", 0)
              .subtract(3, "d")
              .tz("Australia/Sydney")
              .format(),
            message: values.name + " will start in 3 days. " + linkim,
          });
          break;
        case 4: //1 day ago
          zamanlar.push({
            time: moment(values.date)
              .set("hour", 18)
              .set("minute", 0)
              .set("second", 0)
              .subtract(1, "d")
              .tz("Australia/Sydney")
              .format(),
            message: values.name + " will start in tomorrow. " + linkim,
          });
          break;
        case 5: //3 hours ago
          zamanlar.push({
            time: moment(values.date)
              .set("second", 0)
              .subtract(3, "h")
              .tz("Australia/Sydney")
              .format(),
            message: values.name + " will start in 3 hours. " + linkim,
          });
          break;
        case 6: //1 hours ago
          zamanlar.push({
            time: moment(values.date)
              .set("second", 0)
              .subtract(1, "h")
              .tz("Australia/Sydney")
              .format(),
            message: values.name + " will start in 60 minutes. " + linkim,
          });
          break;
        case 7: //15 minutes ago
          zamanlar.push({
            time: moment(values.date)
              .set("second", 0)
              .subtract(15, "m")
              .tz("Australia/Sydney")
              .format(),
            message: values.name + " will start in 15 minutes. " + linkim,
          });
          break;
        case 8: //on time
          zamanlar.push({
            time: moment(values.date)
              .set("second", 0)
              .tz("Australia/Sydney")
              .format(),
            message: values.name + " started. " + linkim,
          });
          break;
        default:
          break;
      }
    });
    setSchedule(zamanlar);
    console.log(zamanlar);
  };
  const onReset = () => {
    form.resetFields();
  };

  const saveSms = () => {
    setLoading2(true);
    const req = { ...formValues, schedule };
    console.log(req);
    AdminService.newSmsEvent(req).then((data) => {
      notification.success({
        message: "Success!",
        description: "Event submitted successfully",
      });
      onReset();
      setSchedule(null);
      setLoading2(false);
    });
  };
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Divider orientation="left">Set New Event SMS Notifications</Divider>
          <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            layout="vertical"
            className="lg:mr-16"
          >
            <Form.Item
              label="Sender"
              name="sender"
              rules={[{ required: true }]}
            >
              <Select placeholder="Select Sender">
                {GlobalVariables.senders.map((g) => (
                  <Select.Option key={g.id} value={g.id}>
                    {g.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Event Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Type event name" />
            </Form.Item>
            <Form.Item
              label="Event Start Date & Time"
              name="date"
              rules={[{ required: true }]}
            >
              <DatePicker
                showTime
                disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                hideDisabledOptions
                format="DD-MM-YY HH:mm"
                minuteStep={15}
                placeholder="Date & Time"
              />
            </Form.Item>

            <Form.Item
              label="Event Details Web Link"
              name="weblink"
              rules={[{ type: "url" }]}
            >
              <Input placeholder="Please enter event url (if any)" />
            </Form.Item>
            <Form.Item
              label="Event Zoom Link"
              name="zoomlink"
              rules={[{ type: "url" }]}
            >
              <Input placeholder="Please enter event zoom link (if any)" />
            </Form.Item>
            <Form.Item
              label="Gender Filter"
              name="gender"
              rules={[{ required: true }]}
            >
              <Select placeholder="Select Gender(s)" mode="multiple" allowClear>
                {GlobalVariables.genders.map((g) => (
                  <Select.Option key={g.id} value={g.id}>
                    {g.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Group Filter"
              name="group"
              rules={[{ required: true }]}
            >
              <Select placeholder="Select Group(s)" mode="multiple" allowClear>
                {GlobalVariables.groups.map((g) => (
                  <Select.Option key={g.id} value={g.id}>
                    {g.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="How long before the event time do you want to send SMS?"
              required
            ></Form.Item>
            <Form.Item
              name="times"
              rules={[{ required: true }]}
              className="-mt-12"
            >
              <Select
                placeholder="Select Notification Times"
                mode="multiple"
                allowClear
              >
                {GlobalVariables.times.map((g) => (
                  <Select.Option key={g.id} value={g.id}>
                    {g.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button htmlType="reset" icon={<ClearOutlined />}>
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<MailOutlined />}
                  loading={loading}
                >
                  Create Messages
                </Button>{" "}
              </Space>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          {schedule && (
            <div>
              <Divider orientation="left">SMS Notifications Check List</Divider>
              Event: {<Tag color="blue">{formValues.name}</Tag>}
              <br />
              <Space direction="horizontal" wrap className="mt-2">
                <div>
                  From :{" "}
                  <Tag>
                    {
                      GlobalVariables.senders.find(
                        (x) => x.id === formValues.sender
                      ).name
                    }
                  </Tag>
                </div>
                <div>
                  Event Date:
                  <Tag>
                    {" "}
                    {moment(formValues.date).format("dddd, DD-MM-YY HH:mm")}
                  </Tag>
                </div>
                <div>
                  {" "}
                  Gender:{" "}
                  {formValues.gender.map((g) => (
                    <Tag key={g}>
                      {GlobalVariables.genders.find((x) => x.id === g).name}
                    </Tag>
                  ))}
                </div>
                <div>
                  {" "}
                  Group:{" "}
                  {formValues.group.map((g) => (
                    <Tag key={g}>
                      {GlobalVariables.groups.find((x) => x.id === g).name}
                    </Tag>
                  ))}
                </div>
              </Space>
              <br />
              <div className="space-y-4 mt-4">
                {schedule
                  .sort((a, b) =>
                    moment(a.time, "DD-MM-YYYY HH:mm").isBefore(
                      moment(b.time, "DD-MM-YYYY HH:mm")
                    )
                      ? -1
                      : 1
                  )
                  .map((s) => (
                    <div>
                      <Card
                        key={s.id}
                        size="small"
                        type="inner"
                        className="max-w-sm border border-gray-300 "
                        title={
                          <span
                            className={
                              moment(s.time, "DD-MM-YYYY HH:mm").isBefore(
                                moment(moment(), "DD-MM-YYYY HH:mm")
                              )
                                ? "text-red-500"
                                : "text-green-600"
                            }
                          >
                            {moment(s.time).format("dddd, DD-MM-YY HH:mm")}
                          </span>
                        }
                      >
                        <Card.Meta
                          title={s.message}
                          description={s.message.length + " character"}
                        />
                      </Card>
                    </div>
                  ))}
              </div>
              <br />
              <Button
                icon={<SaveOutlined />}
                type="primary"
                size="large"
                onClick={saveSms}
                loading={loading2}
              >
                Save to SMS Databsse
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SmsNotifications;
