import { Bar } from "@ant-design/plots";
import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const ToplamGrafigi = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [grafikVerisi, setGrafikVerisi] = useState(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getToplamPuanlar().then((data) => {
        let tmp = [];
        setData(data.data);
        data.data.forEach((d) => {
          var a = {
            kisi: d.mutevelli,
            key: "Ziyaret",
            value: d.ziyaret,
          };
          tmp.push(a);
          a = {
            kisi: d.mutevelli,
            key: "Aile Ziyareti",
            value: d.aileziyareti,
          };
          tmp.push(a);
          a = {
            kisi: d.mutevelli,
            key: "Kamp",
            value: d.kamp,
          };
          tmp.push(a);
          a = {
            kisi: d.mutevelli,
            key: "Mut.Devam",
            value: d.mutDevam,
          };
          tmp.push(a);
          a = {
            kisi: d.mutevelli,
            key: "Sohbet Devam",
            value: d.sohbetDevam,
          };
          tmp.push(a);
        });
        console.log(tmp);
        setGrafikVerisi(tmp);
      });
    }
  }, [initialRender]);
  const config = {
    height: 600,
    isStack: true,
    conversionTag: { size: 20, spacing: 20, text: "selam" },
    xField: "value",
    yField: "kisi",
    seriesField: "key",

    label: {
      // 可手动配置 label 数据标签位置
      position: "middle",

      // 'left', 'middle', 'right'
      // 可配置附加的布局方法
      layout: [
        // 柱形图数据标签位置自动调整
        {
          type: "interval-adjust-position",
        }, // 数据标签防遮挡
        {
          type: "interval-hide-overlap",
        }, // 数据标签文颜色自动调整
        {
          type: "adjust-color",
        },
      ],
    },
  };
  const columns = [
    {
      title: "Mutevelli",
      dataIndex: "mutevelli",
    },
    {
      title: "Mentor",
      dataIndex: "mentor",
    },
    {
      title: "Cinsiyet",
      dataIndex: "gender",
    },
    {
      title: "Ziyaret",
      dataIndex: "ziyaret",
      className: "text-center",
    },
    {
      title: "Aile Ziyareti",
      dataIndex: "aileziyareti",
      className: "text-center",
    },
    {
      title: "Kamp",
      dataIndex: "kamp",
      className: "text-center",
    },
    {
      title: "Mut.Devam",
      dataIndex: "mutDevam",
      className: "text-center",
    },
    {
      title: "Sohbet Devam",
      dataIndex: "sohbetDevam",
      className: "text-center",
    },
    {
      title: "Toplam",
      dataIndex: "toplam",
      className: "text-center font-medium",
    },
  ];
  return (
    <div className="m-2 p-4 bg-white border border-gray-300 max-w-7xl">
      <p className="text-sky-500"> Toplam Puan Grafigi</p>
      {grafikVerisi && <Bar {...config} data={grafikVerisi} />}
      <br />
      <br />
      {data && (
        <Table
          title={() => (
            <span className="text-base text-blue-500">
              {" "}
              Toplam Puan Tablosu{" "}
            </span>
          )}
          footer={() =>
            "Baz Puanlar : Ziyaret: 1 , Aile Ziyareti : 5 , Kamp : 5 , Mutevelli : 1 , Sohbet : 1"
          }
          dataSource={data}
          columns={columns}
          size="small"
          pagination={false}
          bordered
        />
      )}
    </div>
  );
};

export default ToplamGrafigi;
