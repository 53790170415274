import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Empty,
  notification,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

const SmsEvents = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [events, setEvents] = useState(null);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getSmsEvents().then((data) => {
        console.log(data.data);
        setEvents(data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      render: (date) => moment(date).format("dddd, DD-MM-YY HH:mm"),
    },
    {
      title: "SMS Message",
      dataIndex: "message",
    },
    {
      title: "Is Sent",
      dataIndex: "isSent",
      render: (isSent) => (isSent ? "Yes" : "No"),
    },
    {
      title: "Delete",
      render: (item) => (
        <Tooltip title="Delete SMS Message" placement="left">
          <Popconfirm
            title="Are you sure ?"
            onConfirm={() => deleteMessage(item.id)}
            okText="Yes! Delete."
            cancelText="Cancel"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  const deleteEvent = (id) => {
    console.log(id);
    AdminService.deleteSmsEvent(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Event deleted!",
      });
      setInitialRender(true);
    });
  };
  const deleteMessage = (id) => {
    console.log(id);
    AdminService.deleteSmsMessage(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Message deleted!",
      });
      setInitialRender(true);
    });
  };
  return (
    <div>
      {events && events.length === 0 && (
        <Empty className="mt-6" description="Event not found!" />
      )}
      {events &&
        events.map((item) => (
          <Card
            key={item.data.id}
            type="inner"
            size="small"
            className="border border-gray-300 mb-6 max-w-5xl mx-auto"
            title={
              <span className="text-base text-sky-500">{item.data.name}</span>
            }
            extra={
              <>
                <Tag className="text-base">
                  {moment(item.data.date).format("dddd, DD-MM-YY HH:mm")}
                </Tag>{" "}
                <Tag className="text-base" color="blue">
                  {item.sender}
                </Tag>
                <Tooltip title="Delete Event" placement="left">
                  <Popconfirm
                    title="Are you sure ?"
                    onConfirm={() => deleteEvent(item.data.id)}
                    okText="Yes! Delete."
                    cancelText="Cancel"
                  >
                    <Button type="primary" danger icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </>
            }
          >
            Genders:{" "}
            {item.data.genders.map((g) => (
              <Tag color="green" className="text-base">
                {g.name}
              </Tag>
            ))}
            <br />
            <br />
            Groups:{" "}
            {item.data.grups.map((g) => (
              <Tag color="magenta" className="text-base">
                {g.name}
              </Tag>
            ))}
            <br />
            <br />
            Web Link :{" "}
            {item.data.weblink && (
              <Tag color="gold">
                {" "}
                <Link to={{ pathname: `${item.data.weblink}` }} target="_blank">
                  {item.data.weblink}
                </Link>
              </Tag>
            )}
            <br />
            <br />
            Zoom Link :{" "}
            <Tag color="gold">
              {item.data.zoomlink && (
                <Link
                  to={{ pathname: `${item.data.zoomlink}` }}
                  target="_blank"
                >
                  {item.data.zoomlink}
                </Link>
              )}
            </Tag>
            <br />
            <br />
            <Table
              pagination={false}
              className="border border-gray-200"
              title={() => <span>SMS messages for this event</span>}
              size="small"
              columns={columns}
              dataSource={item.data.smsMessages}
              bordered
              rowKey="id"
            />
          </Card>
        ))}
    </div>
  );
};

export default SmsEvents;
