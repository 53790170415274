import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { AdminService } from "../../resources/AdminService";

const ContactForm = () => {
  const [form] = useForm();

  const harita2 = ` <iframe width="100%" height="600" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=27%20Queen%20Street%20Auburn%20NSW%202144&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed";"></iframe>
    `;
  const onFinish = (v) => {
    AdminService.newContact(v).then((data) => {
      notification.success({
        message: "Success",
        description: "Message sent!",
      });
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const states = [
    {
      code: "ACT",
      name: "Australian Capital Territory",
      subdivision: null,
    },
    {
      code: "NSW",
      name: "New South Wales",
      subdivision: null,
    },
    {
      code: "NT",
      name: "Northern Territory",
      subdivision: null,
    },
    {
      code: "QLD",
      name: "Queensland",
      subdivision: null,
    },
    {
      code: "SA",
      name: "South Australia",
      subdivision: null,
    },
    {
      code: "TAS",
      name: "Tasmania",
      subdivision: null,
    },
    {
      code: "VIC",
      name: "Victoria",
      subdivision: null,
    },
    {
      code: "WA",
      name: "Western Australia",
      subdivision: null,
    },
  ];
  return (
    <div className="container mx-auto overflow-x-hidden md:p-4 p-2 max-w-6xl">
      <div>
        <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-700 sm:text-4xl">
          Contact with GALAXY
        </h3>
      </div>
      <Row gutter={[40, 40]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <>
            <br />
            <h2 className="text-base text-red-500 font-semibold tracking-wide uppercase">
              OUR CONTACT DETAILS
            </h2>
            <div
              className=" relative flex flex-wrap py-6   max-w-5xl  "
              style={{
                border: "1px solid #ddd",
                boxShadow: "0 0 5px #eee",
                backgroundColor: "#fafafa",
              }}
            >
              <div className="lg:w-1/2 px-6 leading-relaxed">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p className="mt-1">
                  27 Queen Street, Level 1 <br />
                  Auburn, NSW 2170 <br />
                  Australia
                </p>
              </div>
              <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a
                  href="mailto://info@auf.net.au"
                  className="text-blue-500 leading-relaxed"
                >
                  info@galaxyfoundation.org.au
                </a>
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <a href="tel://296493494" className="leading-relaxed">
                  +61 2 9649 3494
                </a>
              </div>
            </div>
            <br />
            <br />
            <h2 className="text-base text-red-500 font-semibold tracking-wide uppercase">
              CONTACT FORM
            </h2>
            <div
              style={{
                border: "1px solid #ddd",
                boxShadow: "0 0 5px #eee",
                padding: "20px",
                backgroundColor: "#fafafa",
              }}
            >
              <Form form={form} onFinish={onFinish} onReset={onReset}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input placeholder="Your name" />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Email is required" },
                    { type: "email", message: "Invalid Email address" },
                  ]}
                >
                  <Input
                    placeholder="Your email"
                    type="email"
                    autoComplete="email"
                  />
                </Form.Item>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    {states.map((s) => (
                      <Select.Option key={s.code} value={s.code}>
                        {s.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="subject"
                  rules={[{ required: true, message: "Subject is required" }]}
                >
                  <Input placeholder="Your subject" />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[{ required: true, message: "Message is required" }]}
                >
                  <TextArea rows={4} placeholder="Your message" />
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Send
                    </Button>
                    <Button type="primary" ghost htmlType="reset">
                      Clear
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          </>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <>
            <br />

            <h2 className="text-base text-red-500 font-semibold tracking-wide uppercase">
              OUR LOCATION
            </h2>
            <div
              style={{
                border: "2px solid #ddd",
                boxShadow: "0 0 5px #eee",
              }}
              dangerouslySetInnerHTML={{ __html: harita2 }}
            />
          </>
        </Col>
      </Row>
      <br />
      <br />
      <br />
    </div>
  );
};

export default ContactForm;
