import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.less";
import HomeLayout from "./layouts/HomeLayout";
import "react-quill/dist/quill.snow.css";
import NotFound from "./layouts/NotFound";
import AdminLayout from "./layouts/AdminLayout";
import LoginLayout from "./layouts/LoginLayout";
import { AdminRoute } from "./resources/PrivateRoute";
import MentorLayout from "./layouts/MentorLayout";

function App() {
  console.log("path:", window.location.pathname);
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/home" />
      </Route>
      <Route path="/home" component={HomeLayout} />
      <AdminRoute path="/admin" component={AdminLayout} />
      <Route path="/login" component={LoginLayout} />
      <Route path="/mentor" component={MentorLayout} />

      <Route path="/404" component={NotFound} />
      <Route>
        <Redirect to="/404" />
      </Route>
    </Switch>
  );
}

export default App;
