import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "nprogress/nprogress.css";
import { Router } from "react-router-dom";
import { axHistory } from "./resources/axHistory";
import ScrollToTop from "./resources/scrollTop";
import { GlobalContextProvider } from "./context/GlobalContext";
import "moment/locale/en-gb";
import locale from "antd/es/locale/en_GB";
import { ConfigProvider } from "antd";
ReactDOM.render(
  <React.StrictMode>
    <Router history={axHistory}>
      <GlobalContextProvider>
        <ScrollToTop />
        <ConfigProvider locale={locale}>
          <App />
        </ConfigProvider>
      </GlobalContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
