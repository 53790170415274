import React from "react";
import { Link } from "react-router-dom";

/* This example requires Tailwind CSS v2.0+ */
export default function NotFound() {
  return (
    <div className="min-h-screen bg-cover bg-top sm:bg-top bg404">
      <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
        <p className="text-sm font-semibold text-black text-opacity-50 uppercase tracking-wide">
          404 error
        </p>
        <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
          Uh oh! I think you’re lost.
        </h1>
        <p className="mt-2 text-lg font-medium text-black text-opacity-50">
          It looks like the page you’re looking for doesn't exist.
        </p>
        <div className="mt-6">
          <Link
            to="/"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white  bg-mavi  sm:hover:text-white"
          >
            Go back home
          </Link>
        </div>
      </div>
    </div>
  );
}
