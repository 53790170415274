import { PageHeader, Image, Descriptions, Row, Col, Divider } from "antd";
import React from "react";
import ReactPlayer from "react-player";
import { useHistory, useLocation } from "react-router-dom";

const ProjectAdminDetails = () => {
  const location = useLocation();
  // @ts-ignore
  const { veri } = location.state || { veri: null };
  const router = useHistory();
  function createMarkup(gelenDetay) {
    return {
      __html: gelenDetay,
    };
  }
  console.log("veri:", veri);
  return (
    veri && (
      <div style={{ maxWidth: "800px", padding: "0 10px" }}>
        <PageHeader
          className="site-page-header"
          onBack={() => router.goBack()}
          title={veri.name}
        />

        <Descriptions
          size="small"
          bordered
          column={{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item label="Description" span={2}>
            {veri.description}
          </Descriptions.Item>

          <Descriptions.Item label="Is Active ?">
            {veri.isActive ? "Yes" : "No"}
          </Descriptions.Item>
          <Descriptions.Item label="Is Favorite ?">
            {veri.isFavorite ? "Yes" : "No"}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Image
          preview={false}
          style={{
            maxWidth: "800px",
            border: "1px solid #ddd",
          }}
          src={process.env.REACT_APP_FILES_BASEURL + veri.images[0].url}
          alt=""
        />
        <br />
        <br />
        <div dangerouslySetInnerHTML={createMarkup(veri.details)} />
        <br />
        {veri.images.length > 0 && (
          <div>
            <Divider orientation="left">Image Gallery</Divider>
            <Image.PreviewGroup>
              <Row align="middle" gutter={[10, 10]}>
                {veri.images.map((imaj) => (
                  <Col key={imaj.id}>
                    <Image
                      className="gallery-image"
                      width={200}
                      src={process.env.REACT_APP_FILES_BASEURL + imaj.url}
                    />
                  </Col>
                ))}
              </Row>
            </Image.PreviewGroup>
          </div>
        )}
        {veri.videos.length > 0 && (
          <div>
            <Divider orientation="left">Video Gallery</Divider>

            <Row align="middle" gutter={[10, 10]}>
              {veri.videos.map((video) => (
                <Col key={video.id}>
                  <ReactPlayer url={video.url} controls={true} />
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    )
  );
};

export default ProjectAdminDetails;
