import { DeleteOutlined } from "@ant-design/icons";
import { Button, notification, Popconfirm, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const Cikislar = () => {
  const [unsubs, setunsubs] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getUnubscriptions().then((data) => {
        console.log(data.data);
        setunsubs(data.data);
      });
    }
  }, [initialRender]);

  const columns = unsubs && [
    {
      title: "Date",
      dataIndex: "createdOn",
      render: (createdOn) => moment(createdOn).format("DD-MM-YY HH:mm"),
    },
    { title: "Form Name", dataIndex: "name" },
    {
      title: "Record Name",
      dataIndex: "staff",
      render: (staff) => staff?.name || "-",
    },
    { title: "Phone", dataIndex: "phone" },
    { title: "Reason", dataIndex: "reason" },
    {
      title: "Group",
      dataIndex: "staff",
      render: (staff) => staff?.grup || "-",
    },
    {
      title: "Gender",
      dataIndex: "staff",
      render: (staff) => staff?.gender || "-",
    },
    {
      title: "Delete",
      render: (item) => (
        <Tooltip title="Delete Alert" placement="left">
          <Popconfirm
            title="Are you sure ?"
            onConfirm={() => deleteSubs(item.staff?.id)}
            okText="Yes! Delete."
            cancelText="Cancel"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  const deleteSubs = (id) => {
    AdminService.deleteStaffTel(id).then((data) => {
      notification.success({ message: "Success! Deleted!" });
      setInitialRender(true);
    });
  };
  return (
    <div>
      {unsubs && (
        <Table
          rowKey="id"
          columns={columns}
          dataSource={unsubs}
          bordered
          size="small"
        />
      )}
    </div>
  );
};

export default Cikislar;
