import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Image, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../resources/AdminService";

const EventsPage = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [events, setEvents] = useState(null);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getEvents().then((data) => {
        setEvents(data.data);
      });
    }
  }, [initialRender]);
  const goDetails = (veri) => {
    router.push(`/home/events/${veri.key}/details`, { veri });
  };
  return (
    <>
      <div className="text-blueGray-700 max-w-6xl  justify-center flex mx-auto ">
        <div className="inline-block">
          {events &&
            events.map((item) => (
              <div
                key={item.key}
                className="container lg:grid lg:grid-cols-2 gap-6 flex flex-col px-2 py-4 
          mx-auto lg:items-center  lg:px-4 justify-end"
              >
                <div className="w-full lg:my-4 ">
                  <Image
                    className="border shadow-lg rounded-lg"
                    src={
                      process.env.REACT_APP_FILES_BASEURL +
                      item.events.images[0].url
                    }
                  />
                </div>
                <div className="flex flex-col items-start text-left   lg:ml-10">
                  <Space wrap>
                    <span
                      className=" rounded text-xs text-white  border px-1  uppercase title-font"
                      style={{
                        backgroundColor: moment(item.events.date).isAfter(
                          moment(new Date())
                        )
                          ? "green"
                          : "salmon",
                      }}
                    >
                      {moment(item.events.date).isAfter(moment(new Date()))
                        ? "UPCOMING EVENT"
                        : "PAST EVENT"}
                    </span>
                    <span className="font-medium rounded text-xs  border px-1  uppercase title-font">
                      {moment(item.events.date).format("DD-MMM-YY ddd, HH:mm")}
                    </span>
                  </Space>
                  <h1 className="mb-8 text-2xl font-bold tracking-tighter text-left text-black lg:text-3xl title-font">
                    {item.events.title}
                  </h1>
                  <p className="mb-8 text-base leading-relaxed text-left text-blueGray-700 ">
                    {item.events.description}
                  </p>
                  <div className="flex flex-col justify-left lg:flex-row">
                    <Button
                      type="primary"
                      size="large"
                      icon={<ArrowRightOutlined />}
                      onClick={() => goDetails(item)}
                    >
                      Get Details
                    </Button>
                  </div>
                </div>
                <br />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default EventsPage;
