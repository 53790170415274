import { Divider, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const Tefsirciler = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [liste, setListe] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getTefsir().then((data) => {
        setListe(data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (createdAt) => moment(createdAt).format("dddd, DD-MM-YYYY"),
    },
    {
      title: "Ad Soyad",
      dataIndex: "adsoyad",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
  ];
  return (
    <div className="max-w-3xl">
      <Divider orientation="left">Tefsir dersi katilimci listesi</Divider>
      <Table
        dataSource={liste}
        loading={liste === null}
        size="small"
        bordered
        pagination={false}
        columns={columns}
      />
    </div>
  );
};

export default Tefsirciler;
