import { Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const EnSonRaporu = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [rapor, setRapor] = useState(null);
  const [aileziyaretleri, setAileziyaretleri] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getEnsonRaporu().then((data) => {
        console.log(data.data);
        setAileziyaretleri(
          data.data.sort((a, b) =>
            a.aileziyareti?.aileziyareti > b.aileziyareti?.aileziyareti ? 1 : -1
          )
        );
        setRapor(data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Ad",
      dataIndex: "firstname",
    },
    {
      title: "Soyad",
      dataIndex: "lastname",
      //render: (lastname, row) => <span>{lastname + " " + row.firstname}</span>,
    },
    {
      title: "Cinsiyet",
      dataIndex: "gender",
      render: (gender) => (gender === "Male" ? "Erkek" : "Kadın"),
    },
    {
      title: "Mentor",
      dataIndex: "mentor",
    },
    {
      title: "Ziyaret",
      dataIndex: "ziyaret",
      render: (ziyaret) =>
        ziyaret &&
        ziyaret.ziyaret > 0 &&
        moment(ziyaret?.tarih).locale("tr").format("MMMM") +
          " (" +
          ziyaret.ziyaret +
          ")",
      sorter: {
        compare: (a, b) =>
          new Date(a.ziyaret.tarih).getTime() ||
          0 - new Date(b.ziyaret.tarih).getTime(),
      },
    },
    {
      title: "Aile Ziyareti",
      dataIndex: "aileziyareti",
      render: (aileziyareti) =>
        aileziyareti &&
        aileziyareti.aileziyareti > 0 &&
        moment(aileziyareti?.tarih).locale("tr").format("MMMM") +
          " (" +
          aileziyareti.aileziyareti +
          ")",
      sorter: {
        compare: (a, b) =>
          new Date(a.aileziyareti.tarih).getTime() *
            a.aileziyareti.aileziyareti -
          new Date(b.aileziyareti.tarih).getTime() *
            b.aileziyareti.aileziyareti,
      },
    },
    {
      title: "Kamp",
      dataIndex: "kamp",
      render: (kamp) =>
        kamp &&
        kamp.kamp > 0 &&
        moment(kamp?.tarih).locale("tr").format("MMMM") +
          " (" +
          kamp.kamp +
          ")",
      sorter: {
        compare: (a, b) =>
          new Date(a.kamp.tarih).getTime() - new Date(b.kamp.tarih).getTime(),
      },
    },
    {
      title: "Mutevelli",
      dataIndex: "mutDevam",
      render: (mutDevam) =>
        mutDevam &&
        mutDevam.mutDevam > 0 &&
        moment(mutDevam?.tarih).locale("tr").format("MMMM") +
          " (" +
          mutDevam.mutDevam +
          ")",
      sorter: {
        compare: (a, b) =>
          new Date(a.mutDevam.tarih).getTime() * a.mutDevam.mutDevam -
          new Date(b.mutDevam.tarih).getTime() * b.mutDevam.mutDevam,
      },
    },
    {
      title: "Sohbet",
      dataIndex: "sohbetDevam",
      render: (sohbetDevam) =>
        sohbetDevam &&
        sohbetDevam.sohbetDevam > 0 &&
        moment(sohbetDevam?.tarih).locale("tr").format("MMMM") +
          " (" +
          sohbetDevam.sohbetDevam +
          ")",
      sorter: {
        compare: (a, b) =>
          new Date(a.sohbetDevam.tarih).getTime() * a.sohbetDevam.sohbetDevam -
          new Date(b.sohbetDevam.tarih).getTime() * b.sohbetDevam.sohbetDevam,
      },
    },
  ];
  return (
    <div className="max-w-4xl">
      {rapor && (
        <Table
          className="mb-10"
          pagination={false}
          bordered
          size="small"
          title={() => (
            <span className="text-blue-500">
              Mutevelli ilgi raporu (Siralama yapabilirsiniz)
            </span>
          )}
          dataSource={rapor.sort((a, b) =>
            a.ziyaret?.tarih > b.ziyaret?.tarih ? -1 : 1
          )}
          columns={columns}
        />
      )}
    </div>
  );
};

export default EnSonRaporu;
