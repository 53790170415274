import {
  LockOutlined,
  MailOutlined,
  ManOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

const NewMentor = () => {
  const [bloading, setBloading] = useState(false);
  const router = useHistory();
  //const [phone, setPhone] = useState(null);
  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const [mentors, setMentors] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getMentors().then((data) => {
        console.log(data.data);
        setMentors(data.data);
      });
    }
  }, [initialRender]);
  const onFinish = (values) => {
    console.log(values);
    AdminService.newMentor(values).then((data) => {
      notification.success({
        message: "Success",
        description: "Sign up completed successfully",
      });
      onReset();
      setBloading(false);
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const columns = [
    {
      title: "Full Name",
      dataIndex: "name",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
  ];

  return (
    <div>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <div className="m-4 p-4 border border-gray-300  bg-gray-100">
            <Divider orientation="left">New Mentor Form</Divider>
            <Form
              name="normal_login"
              initialValues={{ iam: "donor" }}
              onFinish={onFinish}
              onReset={onReset}
              form={form}
            >
              <Form.Item
                name="fullname"
                rules={[{ required: true, message: "Please type full name" }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Please type full name"
                />
              </Form.Item>
              <Form.Item
                name="genderId"
                rules={[{ required: true, message: "Please select gender" }]}
              >
                <Select
                  placeholder={
                    <span>
                      <ManOutlined /> Please select gender
                    </span>
                  }
                >
                  <Select.Option key={1} value={1}>
                    Male
                  </Select.Option>
                  <Select.Option key={2} value={2}>
                    Female
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Email is required" },
                  { type: "email", message: "Invalid e-mail address" },
                ]}
              >
                <Input
                  autoComplete="nope"
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Please type e-mail"
                />
              </Form.Item>
              <Form.Item name="phone">
                <Input
                  autoComplete="nope"
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                  placeholder="Please type phone (opt.)"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Password is required" },
                  {
                    pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,25}$/,
                    message: "Must be 6-25 digit and include numbers",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Please type password"
                />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "This field required",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Passwords do not match");
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Password again"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={bloading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Divider orientation="left">Added Mentors</Divider>
          <Table
            dataSource={mentors}
            columns={columns}
            rowKey="id"
            loading={mentors === null}
            pagination={false}
            size="small"
            bordered
          />
        </Col>
      </Row>
    </div>
  );
};

export default NewMentor;
