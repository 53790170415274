import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Space,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const AcTypes = () => {
  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const [campTypes, setCampTypes] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getCampActypes().then((data) => {
        console.log(data.data);
        setCampTypes(data.data);
      });
    }
  }, [initialRender]);

  const onFinish = (v) => {
    console.log(v);
    AdminService.newCampAcType(v).then((data) => {
      setInitialRender(true);
      notification.success({ message: "Accommodation type saved!" });
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const columns = [
    {
      title: "Accommodation Type",
      dataIndex: "name",
    },
    {
      title: "Price (per night)",
      dataIndex: "price",
      render: (price) => <span>${price}</span>,
    },
    {
      title: "Color on map",
      dataIndex: "color",
    },
  ];
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <div className="p-4 max-w-xl bg-white border border-gray-300 rounded-md shadow-xl">
          <Divider orientation="left">New Accommodation Type</Divider>
          <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            layout="vertical"
          >
            <Form.Item
              label="Accommodation Type"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Accommodation type name" />
            </Form.Item>
            <Form.Item
              label="Nightly price"
              name="price"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
            <Form.Item
              label="Color on map"
              name="color"
              rules={[{ required: true }]}
            >
              <Input placeholder="Type color on map" />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
                <Button htmlType="reset" icon={<ClearOutlined />}>
                  Reset
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <div className="p-4 max-w-xl bg-white border border-gray-300 rounded-md shadow-xl">
          <Divider orientation="left">Saved Accommodation Types</Divider>

          <Table
            columns={columns}
            loading={campTypes === null}
            size="small"
            pagination={false}
            bordered
            dataSource={campTypes}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AcTypes;
