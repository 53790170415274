import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginPage from "../components/login/LoginPage";
import SignupPage from "../components/login/SignupPage";

const LoginLayout = () => {
  return (
    <div>
      <Switch>
        <Route exact path={"/login"}>
          <Redirect to={"/login/loginpage"} />
        </Route>
        <Route path={"/login/loginpage"}>
          <LoginPage />
        </Route>
        <Route path={"/login/signuppage"}>
          <SignupPage />
        </Route>
        {/*  <Route path={"/login/agreement"}>
        <Agreement />
      </Route>
      <Route path={"/login/forgetpassword"}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_reCaptchaKey}
        >
          <ForgetPassword />
        </GoogleReCaptchaProvider>
      </Route>
      <Route path={"/login/newpassword"}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_reCaptchaKey}
        >
          <NewPassword />
        </GoogleReCaptchaProvider>
      </Route> */}
        <Route>
          <Redirect to="/login" />
        </Route>
      </Switch>
    </div>
  );
};

export default LoginLayout;
