import { FileSearchOutlined, HeartOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Image,
  Radio,
  Row,
  Spin,
} from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import SpecsBanner from "./SpecsBanner";

const ProjectsPage = () => {
  const [grup, setGrup] = useState(0);
  const [initialRender, setInitialRender] = useState(true);
  const [projects, setProjects] = useState(null);
  const [projectsMaster, setProjectsMaster] = useState([]);
  const router = useHistory();
  const options = [
    { label: "All Projects", value: 0 },
    { label: "General Projects", value: 1 },
    { label: "Scholarship Projects", value: 2 },
  ];

  useEffect(() => {
    if (initialRender) {
      window.scroll(0, 0);
      setInitialRender(false);
      AdminService.getProjects().then((data) => {
        setProjects(data.data);
        setProjectsMaster(data.data);
      });
    }
  }, [initialRender]);

  const grupChanged = (g) => {
    setGrup(g.target.value);
    setProjects(
      g.target.value === 0
        ? projectsMaster
        : projectsMaster.filter((p) => p.project.pType === g.target.value)
    );
  };
  const goDetails = (id, detay) => {
    router.push("/home/projects/" + id, { detay });
  };
  return (
    <div
      className="container"
      style={{ padding: "30px 10px", textAlign: "center" }}
    >
      <h1
        className="font-merri fw-900"
        style={{
          fontSize: "36px",
          textAlign: "center",
          color: "#d46b08",
        }}
      >
        Galaxy Foundation Projects
      </h1>
      {/* <h3 style={{ textAlign: "center", color: "gray", lineHeight: "16px" }}>
        Scholarship Donations of $2.00 or more are Tax Deductible.
      </h3> */}
      <br />
      <Radio.Group
        options={options}
        onChange={grupChanged}
        value={grup}
        buttonStyle="solid"
        optionType="button"
      />
      <Divider />
      {projects && projects.length !== 0 ? (
        <Row gutter={[16, 16]}>
          {projects.map((proje, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              style={{
                display: "inline-flex",
                alignSelf: "stretch",
              }}
            >
              <Card
                cover={
                  <Image
                    preview={false}
                    alt="example"
                    src={
                      process.env.REACT_APP_FILES_BASEURL +
                      proje.project.images[0].url
                    }
                  />
                }
                className="kart"
                style={{
                  textAlign: "center",
                  border: "1px solid #ddd",
                  boxShadow: "0 0 10px #eee",
                  transition: "all ease 200ms",
                  paddingBottom: "45px",
                }}
                actions={[
                  <Button
                    icon={<FileSearchOutlined />}
                    type="link"
                    onClick={() => goDetails(proje.project.id, true)}
                  >
                    Details
                  </Button>,
                  <Button
                    icon={<HeartOutlined />}
                    type="primary"
                    onClick={() => goDetails(proje.project.id, false)}
                  >
                    Donate
                  </Button>,
                ]}
              >
                <Meta
                  title={proje.project.name}
                  description={proje.project.description}
                />
              </Card>
            </Col>
          ))}
        </Row>
      ) : projects === null ? (
        <Spin />
      ) : (
        <Empty />
      )}
      <br />
      <br />
      <br />
      <br />
      <SpecsBanner />
      <br />
    </div>
  );
};

export default ProjectsPage;
