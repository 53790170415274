import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Divider, Form, Input, Button, notification, Row } from "antd";
import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
//import PhoneInput from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

const SignupPage = () => {
  const [bloading, setBloading] = useState(false);
  const router = useHistory();
  //const [phone, setPhone] = useState(null);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log(values);
    AdminService.signupMember(values).then((data) => {
      notification.success({
        message: "Success",
        description: "Sign up completed successfully",
      });
      onReset();
      setBloading(false);
      router.push("/login");
    });
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="login-page">
      <div className="login-form-container">
        <img
          onClick={() => router.goBack()}
          className="login-logo"
          src={require("../../assets/images/logo.png").default}
          alt="logo"
          style={{ cursor: "pointer" }}
        />
        <Divider>Register Form</Divider>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ iam: "donor" }}
          onFinish={onFinish}
          onReset={onReset}
          form={form}
        >
          <Form.Item
            name="fullname"
            rules={[{ required: true, message: "Please type your name" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Please type your name"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "Invalid e-mail address" },
            ]}
          >
            <Input
              autoComplete="nope"
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Please type your e-mail"
            />
          </Form.Item>
          <Form.Item name="phone">
            <Input
              autoComplete="nope"
              prefix={<PhoneOutlined className="site-form-item-icon" />}
              placeholder="Please type your phone (opt.)"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Password is required" },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,25}$/,
                message: "Must be 6-25 digit and include numbers",
              },
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Please type password"
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "This field required",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match");
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password again"
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={bloading}
              block
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Sign Up
            </Button>
          </Form.Item>
          <Form.Item style={{ marginTop: "-15px" }}>
            <Row justify="space-between">
              <Button
                onClick={() => router.push("/login")}
                type="primary"
                ghost
                size="small"
              >
                Go Login
              </Button>
              <Button
                onClick={() => router.push("/login/forgetpassword")}
                type="primary"
                ghost
                size="small"
              >
                Forget Password
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SignupPage;
