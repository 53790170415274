import { DeleteOutlined } from "@ant-design/icons";
import { Button, notification, Popconfirm, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "../../resources/AdminService";

const Subscriptions = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [subs, setSubs] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getSubscriptions().then((data) => {
        setSubs(data.data);
      });
    }
  }, [initialRender]);
  const deleteSubs = (id) => {
    AdminService.deleteSubscriptions(id).then((data) => {
      notification.success({
        message: "Success",
        description: "Subscription deleted!",
      });
    });
    setInitialRender(true);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "item",
      render: (item) => item.name,
    },
    {
      title: "Email",
      dataIndex: "item",
      render: (item) => item.email,
    },
    {
      title: "Phone",
      dataIndex: "item",
      render: (item) => item.phone,
    },
    {
      title: "Subs.Date",
      dataIndex: "item",
      render: (item) => moment(item.createdOn).format("DD-MM-YY"),
    },
    {
      title: "Actions",
      dataIndex: "item",
      render: (item) => (
        <Tooltip title="Delete Subscription" placement="left" key={item.id}>
          <Popconfirm
            title="Are you sure ?"
            onConfirm={() => deleteSubs(item.id)}
            okText="Yes! Delete."
            cancelText="Cancel"
          >
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              size="small"
            />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  return (
    <div>
      <h1 className="my-4 text-2xl text-red-600 font-extrabold">
        Subscriptions List
      </h1>
      <Table
        size="small"
        bordered
        dataSource={subs}
        loading={subs == null}
        columns={columns}
      />
    </div>
  );
};

export default Subscriptions;
