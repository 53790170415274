import {
  PlusOutlined,
  MinusCircleOutlined,
  SaveOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AdminService } from "../../../resources/AdminService";
import { GlobalVariables } from "../../../resources/GlobalVariables";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const EventEdit = () => {
  const [form] = useForm();
  const [quillValue, setQuillValue] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [platformlar, setPlatformlar] = useState([]);
  const [tipim, setTipim] = useState("online");
  const { lg } = useBreakpoint();
  const { Option } = Select;
  const location = useLocation();
  const router = useHistory();
  // @ts-ignore
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  // @ts-ignore
  const { veri } = location.state || { veri: null };

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      if (veri === null) {
        router.push("/admin/events");
      } else {
        setQuillValue(veri.events.details);
        setTipim(veri.events.tip);

        veri.events.images.forEach((imaj) => {
          imaj.url = process.env.REACT_APP_FILES_BASEURL + imaj.url;
        });

        setFileList(veri.events.images.filter((i) => i.isTop === true));
        setFileList2(veri.events.images.filter((i) => i.isTop !== true));

        const platforms = [];
        veri.events.zoom !== null && platforms.push("zoom");
        veri.events.meet !== null && platforms.push("meet");
        veri.events.youtube !== null && platforms.push("youtube");
        veri.events = { ...veri.events, platforms };
        setPlatformlar(platforms);
        veri.events.date = moment(veri.events.date);
        setItem(veri.events);
      }
    }
  }, [id, initialRender, router, veri]);
  const onFinish = (values) => {
    if (fileList.length === 0) {
      notification.error({
        message: "Error!",
        description: "Please upload banner image",
      });
      return;
    }

    if (quillValue === "") {
      notification.error({
        message: "Error!",
        description: "Please type news details",
      });
      return;
    }

    const imajlar = [];
    if (fileList.length > 0) {
      if (fileList[0].id !== undefined) {
        fileList[0].url = fileList[0].url.split("\\").pop().split("/").pop();
        imajlar.push({
          id: fileList[0].id,
          url: fileList[0].url,
          isTop: true,
        });
      } else {
        imajlar.push({ url: fileList[0].response.url, isTop: true });
      }
    }
    if (fileList2.length > 0) {
      fileList2.forEach((i) => {
        if (i.id !== undefined) {
          i.url = i.url.split("\\").pop().split("/").pop();
          imajlar.push({ id: i.id, url: i.url, isTop: false });
        } else {
          imajlar.push({ url: i.response.url, isTop: false });
        }
      });
    }

    const req = {
      id: veri.events.id,
      description: values.description,
      title: values.title,
      details: quillValue,
      isActive: true,
      isFavorite: values.isFavorite,
      images: imajlar,
      videos: values.videos,
      date: moment(values.date).format(),
      meet: values.meet,
      youtube: values.youtube,
      zoom: values.zoom,
      tip: values.tip,
    };
    AdminService.editEvents(req).then((data) => {
      notification.success({
        message: "Success!",
        description: "Event added successfully",
      });
      router.push("/admin/events");
    });
  };
  const onReset = () => {
    form.resetFields();
    setFileList([]);
    setFileList2([]);
    quillChange("");
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const quillChange = (e) => {
    setQuillValue(e);
  };

  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };
  const handleChange = ({ file, fileList }) => {
    setFileList2(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const changeTip = (tip) => {
    setTipim(tip);
  };
  const platformChange = (tipler) => {
    //  setTipim(tip);
    setPlatformlar(tipler);
  };
  return (
    <div className="max-w-5xl">
      {item && (
        <Form
          layout={!lg ? "vertical" : "horizontal"}
          form={form}
          onFinish={onFinish}
          onReset={onReset}
          initialValues={{ ...veri.events }}
          {...GlobalVariables.formLayout}
        >
          <Form.Item name="tip" label="Event Type">
            <Select onChange={changeTip}>
              <Select.Option key="reel" value="reel">
                Reel Event
              </Select.Option>
              <Select.Option key="online" value="online">
                Online Event
              </Select.Option>
            </Select>
          </Form.Item>
          {tipim === "reel" && (
            <Form.Item name="location" label="Event Location">
              <Input placeholder="Type Event Location" />
            </Form.Item>
          )}
          {tipim === "online" && (
            <Form.Item
              name="platforms"
              label="Event Platforms"
              rules={[
                {
                  required: true,
                  message: "Please select online event platforms!",
                  type: "array",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select online event platforms"
                onChange={platformChange}
              >
                <Option value="zoom">Zoom Event</Option>
                <Option value="youtube">Youtube Live</Option>
                <Option value="meet">Google Meet</Option>
              </Select>
            </Form.Item>
          )}
          {platformlar.findIndex((p) => p === "zoom") !== -1 && (
            <Form.Item label="Zoom Link" name="zoom">
              <Input placeholder="Please paste event zoom link" />
            </Form.Item>
          )}
          {platformlar.findIndex((p) => p === "youtube") !== -1 && (
            <Form.Item label="Youtube Live Link" name="youtube">
              <Input placeholder="Please paste event youtube live link" />
            </Form.Item>
          )}
          {platformlar.findIndex((p) => p === "meet") !== -1 && (
            <Form.Item label="Meet Link" name="meet">
              <Input placeholder="Please paste event meet link" />
            </Form.Item>
          )}
          <Form.Item
            name="date"
            label="Event Date / Time"
            rules={[
              { type: "object", required: true, message: "Date/Time required" },
            ]}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              placeholder="Select date/time"
            />
          </Form.Item>
          <Form.Item
            name="title"
            label="Event Title"
            rules={[{ required: true, message: "Title required" }]}
          >
            <Input placeholder="Pleace type title" />
          </Form.Item>
          <Form.Item
            label="Short Description"
            name="description"
            rules={[{ required: true, message: "Short description required" }]}
          >
            <Input placeholder="Pleace type short description" />
          </Form.Item>
          <Form.Item
            label="Details"
            rules={[{ required: true, message: "Details required" }]}
          >
            <ReactQuill
              modules={modules}
              theme="snow"
              onChange={(e) => quillChange(e)}
              placeholder="Type news long details"
              value={quillValue}
            />
          </Form.Item>
          <Form.Item label="Banner Image (required)">
            <div className="clearfix">
              <Upload
                action={process.env.REACT_APP_API_BASEURL + "upload"}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }}
                listType="picture-card"
                fileList={fileList}
                onChange={handleChangeImage}
                onPreview={handlePreview}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
            <small>Aspect Ratio must be 16:9</small>
          </Form.Item>
          <Form.Item label="Gallery Images (to Bottom)">
            <>
              <Upload
                action={process.env.REACT_APP_API_BASEURL + "upload"}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }}
                listType="picture-card"
                fileList={fileList2}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </>
          </Form.Item>
          <Form.List name="videos">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "url"]}
                      fieldKey={[fieldKey, "url"]}
                      rules={[{ required: true, message: "Missing url" }]}
                    >
                      <Input
                        placeholder="Paste Video Link"
                        style={{ width: "300px" }}
                      />
                    </Form.Item>

                    <Form.Item
                      valuePropName="checked"
                      name={[name, "isTop"]}
                      fieldKey={[fieldKey, "isTop"]}
                      initialValue={false}
                    >
                      <Checkbox>isTop</Checkbox>
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Video
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item {...GlobalVariables.tailLayout}>
            <Space>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Save
              </Button>
              <Button htmlType="reset" icon={<ClearOutlined />}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EventEdit;
