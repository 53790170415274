import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Row,
  Form,
  TimePicker,
  Input,
  Space,
  Divider,
  Table,
  Spin,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

const SetDay = () => {
  const [dateValue, setDateValue] = useState(null);
  const [programs, setPrograms] = useState(null);
  const [form] = Form.useForm();
  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setDateValue(date);
  };
  const createDate = () => {
    const req = {
      date: moment(dateValue).format(),
    };
    console.log(req);
    AdminService.getTimesForDate(req).then((data) => {
      console.log(data.data);
      setPrograms(data.data);
    });
  };
  const onFinish = (v) => {
    console.log(v);
    const d = moment(dateValue).format("YYYY-MM-DD");
    const t1 = moment(v.times[0]).format("HH:mm:ss");
    const t2 = moment(v.times[1]).format("HH:mm:ss");
    let isoTimestamp1 = `${d}T${t1}`;
    let isoTimestamp2 = `${d}T${t2}`;
    let start = moment(isoTimestamp1).format();
    let end = moment(isoTimestamp2).format();
    console.log(start, end);
    const req = {
      start,
      end,
      program: v.program,
      platform: v.platform,
      link: v.link,
      language: v.language,
    };
    AdminService.newProgramTime(req).then((data) => {
      console.log(data.data);
      setPrograms(data.data);
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const columns = [
    {
      title: "Time",
      render: (item) => (
        <span>
          {moment(item.start).format("HH:mm")} -{" "}
          {moment(item.end).format("HH:mm")}
        </span>
      ),
    },
    {
      title: "Durations",
      render: (item) => (
        <span>
          {moment
            .duration(moment(item.end).diff(moment(item.start)))
            .asMinutes()}{" "}
          min
        </span>
      ),
    },
    {
      title: "Program",
      render: (item) => (
        <span>
          {item.program}{" "}
          {item.language && (
            <span className="text-yellow-600">
              ({item.language.toUpperCase()})
            </span>
          )}
        </span>
      ),
      className: "text-cyan-500 font-semibold",
    },
    {
      title: "Plaform",
      dataIndex: "platform",
    },
    {
      title: "Link",
      dataIndex: "link",
      render: (link) =>
        link && (
          <Link
            to={{ pathname: link }}
            target="_blank"
            className="text-cyan-500 font-semibold"
          >
            Click For Link
          </Link>
        ),
    },
  ];
  return (
    <div>
      <DatePicker
        onChange={onChange}
        format="DD MMMM YY dddd"
        className="w-64"
      />
      <Button className="ml-1" type="primary" onClick={() => createDate()}>
        Create Day
      </Button>
      <div className="p-4 mt-4 bg-f5f5f5 border border-gray-200">
        <Row gutter={[26, 26]}>
          <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
            <Form
              form={form}
              onFinish={onFinish}
              onReset={onReset}
              layout="vertical"
            >
              <Form.Item
                label="Program Start-End Time"
                name="times"
                rules={[{ required: true, message: "Times required" }]}
              >
                <TimePicker.RangePicker format="HH:mm" minuteStep={5} />
              </Form.Item>
              <Form.Item
                label="Program"
                name="program"
                rules={[{ required: true, message: "This field required!" }]}
              >
                <Input placeholder="Type program title for this program" />
              </Form.Item>
              <Form.Item label="Platform (optional)" name="platform">
                <Input placeholder="Type platform for this program (zoom/youtube vs.)" />
              </Form.Item>
              <Form.Item label="Zoom/Youtube Link (optional)" name="link">
                <Input placeholder="Type program link" />
              </Form.Item>

              <Form.Item label="Language (optional)" name="language">
                <Input placeholder="Type program language" />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                  <Button htmlType="reset" icon={<ClearOutlined />}>
                    Clear
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
            <Spin spinning={!dateValue}>
              <Divider orientation="left">
                {moment(dateValue).format("DD MMMM YY dddd")} Program
              </Divider>
              <Table
                pagination={false}
                rowKey="id"
                dataSource={programs}
                columns={columns}
                size="small"
              />
            </Spin>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SetDay;
