import { Col, Row, Statistic } from "antd";
import React from "react";

const AboutPage = () => {
  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto pt-4 pb-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-100 absolute top-0 bottom-0 left-3/4 w-screen border-l" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-mavi sm:text-4xl">
              About GALAXY
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-300"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg shadow-lg object-cover object-center"
                    src={require("../../assets/images/about.jpg").default}
                    alt="about"
                    width="100%"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-4 lg:-mt-2 hakkinda">
            <div className="mt-3  text-gray-700 text-base mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1 leading-6">
              <p>
                Originating from a schooling initiative in 1994 by a group of
                dedicated community volunteers, Galaxy Foundation (formerly
                known as Feza Foundation) is engaged in various educational,
                charitable and social activities that surround Australians
                today.
              </p>
              <br />
              <p>
                Our dream – to defeat ignorance through education, poverty
                through sharing and division through unity – is today’s reality.
              </p>
              <br />
              <p>
                Our initiative Amity College is an independent private school,
                which opened its doors to its first 33 students in 1996. With
                enormous support and relentless efforts of the Australian
                community, Amity College is now operating in three different
                campuses across NSW. With more than 1,700 students enrolled from
                kindergarden to year 12 and students deriving from 118 different
                suburbs and 24 different ethnic backgrounds, Amity College has
                became a truly multicultural Australian school recently.
              </p>
              <br />
              <p>
                With its dedicated staff, delivering best teaching resources and
                facilities available and providing a caring, supportive and safe
                environment where all students are able to develop academically,
                socially and morally, Amity College became an education
                powerhouse in its second decade. Now being regarded as one of
                the most successful schools across NSW, nearly all Amity
                gradutes were placed in universities in 2008 and 2009.
              </p>
              <br />
              <p>
                As an integral part of our educational activities, we also
                provide scholarships to financially needy students and establish
                boarding houses to address boarding needs of high school and
                university students. Our Foundation also conducts subscription
                services for a number of magazines from Turkey to cater for
                educational and recreational needs of the community.
              </p>
              <br />
              <p>
                In addition to enhancing educational opportunities available to
                new generations of Australia, our Foundation is also encourages
                welfare activities to the poor in dire need across the globe. To
                alleviate grieving effects of the natural disasters and regional
                imbalances, we organised emergency relief and human aid
                campaigns which were sent to Bangladesh, Cambodia, China, Laos,
                Malawi, Mozambique, Papua New Guinea, Thailand, Tanzania,
                Turkey, Vietnam and Zimbabwe. Our college students also donated
                money to the victims of the Bali bombings and the victims of the
                bushfires in the ACT and Victoria.
              </p>
              <br />
              <p>
                Our history would not be complete without mentioning the annual
                qurban campaigns we organise each year. Using Galaxy Foundation
                as a distribution channel since 1996, considerate members of the
                Australian community have sent hundreds of thousands of qurbans
                (sacrifical meat as part of the Muslim tradition of Eid ul Adha)
                to poor and needy people accross the globe. To date, qurban
                donations has been sent to Afghanistan, Bangladesh, Bosnia,
                Cambodia, Ghana, India, Indonesia, Laos, Malawi, Mozambique,
                Pakistan, Phillippines, South Africa, Sudan, Thailand, Turkey,
                Turkmenistan and Vietnam.
              </p>
              <br />
              <p>
                Contributing to multicultural mosaic of Australia, a major
                annual function organised by our Foundation is the Annual Qurban
                Festival. We have been organising this event for the past 16
                years with around 5,000 people attending the function on a
                regular basis.
              </p>
              <br />
              <p>
                While we concentrated primarily on education, our history of
                community involvement parallels our growth as an institution. We
                hold annual iftar dinners (dinners at the Islamic month of
                Ramadhan) on a yearly basis with distinguished guests attending
                our dinners each year. To educate the Australian Turkish
                community, we organise conferences on social and religious
                issues as well as current affairs. We occasionally organise
                concerts, bringing well-known artists and performers to
                Australia. While our family camps provide an environment for
                socialising for tens of families, our youth camps ensure social
                inclusion for young members of the Australian community enriched
                by various programs of social and sporting activities.
              </p>
            </div>
            <br />
            <br />
            <h2 className="text-base text-mavi font-semibold tracking-wide uppercase">
              GALAXY BOARD
            </h2>
            <p>
              GALAXY Board Consist of the representatives of our members and the
              following executive officers.
            </p>
            <br />
            <Row gutter={16}>
              <Col span={12}>
                <Statistic
                  title="AUF Chairman"
                  value="Mr Ismail Aydogan"
                  valueStyle={{ fontSize: "18px" }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Secretary"
                  value="Mr M.Samet Kayacik"
                  valueStyle={{ fontSize: "18px" }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
