import { Button, Divider, Form, Input, notification, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

const Unsubscribe = () => {
  const [form] = useForm();
  const router = useHistory();

  const onFinish = (v) => {
    console.log(v);
    v = { ...v, phone: v.phone.trim() };
    AdminService.newUnubscription(v).then((data) => {
      notification.success({
        message: "Success!",
        description: "You will unsubscribe as soon as possible",
      });
      onReset();
      router.push("/home");
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div className="p-2 lg:p-6 max-w-md">
      <Divider orientation="left">Unsubscribe Form</Divider>
      <Form form={form} onFinish={onFinish} onReset={onReset} layout="vertical">
        <Form.Item label="Your Name" name="name" rules={[{ required: true }]}>
          <Input placeholder="Type your name" />
        </Form.Item>
        <Form.Item label="Your Phone" name="phone" rules={[{ required: true }]}>
          <Input placeholder="Type your phone" />
        </Form.Item>
        <Form.Item label="Reason" name="reason" rules={[{ required: true }]}>
          <Input placeholder="Type reason" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Unsubscribe
            </Button>
            <Button type="primary" ghost htmlType="reset">
              Clear
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Unsubscribe;
