import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  GiftOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  PageHeader,
  Row,
  Col,
  Divider,
  Table,
  Space,
  Button,
  notification,
  Popconfirm,
  Switch,
  Tooltip,
} from "antd";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import NewProject from "./NewProject";

const Projects = () => {
  const router = useHistory();
  const [projects, setProjects] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [newPanel, setNewPanel] = useState(false);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getProjects().then((data) => {
        setProjects(data.data);
      });
    }
  }, [initialRender]);

  const deleteProject = (id) => {
    AdminService.deleteProject(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Your Project has been deleted successfully",
      });
      setInitialRender(true);
    });
  };

  const activeChanged = (a) => {
    AdminService.changeProjectActive(a.id).then((data) => {
      setInitialRender(true);
      notification.success({
        message: "Success!",
        description: "Project updated",
      });
    });
  };

  const favoriteChanged = (a) => {
    AdminService.changeProjectFavorite(a.id).then((data) => {
      setInitialRender(true);
      notification.success({
        message: "Success!",
        description: "Project updated",
      });
    });
  };
  const featuredChanged = (a) => {
    AdminService.changeProjectFeatured(a.id).then((data) => {
      setInitialRender(true);
      notification.success({
        message: "Success!",
        description: "Project updated",
      });
    });
  };

  const goDetails = (veri) => {
    router.push("/admin/projects/details", { veri });
  };
  const goEdit = (veri) => {
    router.push("/admin/projects/edit", { veri });
  };
  const columns = [
    {
      title: "Project Name",
      dataIndex: "project",
      render: (project) => project.name,
    },

    {
      title: "is Active?",
      dataIndex: "project",
      render: (item) => (
        <Switch checked={item.isActive} onChange={() => activeChanged(item)} />
      ),
    },
    {
      title: "is Favorite?",
      dataIndex: "project",
      render: (item) => (
        <Switch
          checked={item.isFavorite}
          onChange={() => favoriteChanged(item)}
        />
      ),
    },
    {
      title: "is Featured?",
      dataIndex: "project",
      render: (item) => (
        <Switch
          checked={item.isFeatured}
          onChange={() => featuredChanged(item)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "project",
      render: (project) => (
        <Space>
          <Tooltip title="Delete Project" placement="left">
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => deleteProject(project.id)}
              okText="Yes! Delete."
              cancelText="Cancel"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Project Details" placement="left">
            <Button
              type="primary"
              ghost
              onClick={() => goDetails(project)}
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip title="Edit Project" placement="left">
            <Button
              type="default"
              onClick={() => goEdit(project)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Project Donations" placement="left">
            <Button
              type="primary"
              onClick={() =>
                router.push(`/admin/projects/${project.id}/donations`)
              }
              icon={<GiftOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div className="container mx-auto">
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title="Projects"
        subTitle="You can add new project"
        extra={[
          <Button
            key="new"
            type="primary"
            icon={!newPanel ? <PlusOutlined /> : <CloseOutlined />}
            onClick={() => setNewPanel(!newPanel)}
            danger={newPanel}
          >
            {!newPanel ? "Add New Project" : "Cancel"}
          </Button>,
        ]}
      />
      <Row gutter={[16, 16]}>
        {!newPanel && (
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Divider orientation="left">Added projects list</Divider>
            <Table
              scroll={{ x: 1000 }}
              dataSource={projects}
              bordered
              pagination={false}
              size="small"
              columns={columns}
              className="table-shadow"
              footer={() => `${projects.length} projects listed`}
            />
          </Col>
        )}
        {newPanel && (
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <NewProject sir={setInitialRender} panel={setNewPanel} />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Projects;
