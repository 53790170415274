import { SendOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, notification } from "antd";
import React from "react";
import { AdminService } from "src/resources/AdminService";

const Tefsir = () => {
  const onFinish = (v) => {
    console.log(v);
    AdminService.newTefsir(v).then((data) => {
      notification.success({ message: "Saved successfully!" });
    });
  };
  return (
    <div>
      <img src="/moon.jpg" className="w-full" />
      <div className="p-2 lg:p-6 max-w-5xl mx-auto">
        <p className="text-xl font-bold text-yellow-600 mb-6">
          Müzakereli Tefsir Çalışması 2023
        </p>
        <p>
          "Kur'ân-ı Kerim'den hemen herkes bazı şeyler anlasa da onu bütün
          derinlikleriyle kavrayıp ihata edebilmek, bu alanda gerekli donanımı
          olan tefsir ve te'vil erbabının işidir.”
        </p>
        <br />
        <p>
          “Allah bu emirle ne demek istiyordu? Bu fermanıyla kastı neydi? Ancak
          böyle derin tefsirlere girilince o incelikler anlaşılabilir. Defaatla
          tekrar ettiğim gibi bir kere daha tekrar ediyorum; meallerle
          tercümelerle o incelikler anlaşılmaz. Ama insanların bütün bütün
          yabancı kalmamaları için şerhli haşiyeli meallerle bari bu kadarcık
          Kur’an’ın içinde olalım. Yani cevazına bir menat, bir mesnet
          aranıyorsa şayet, işte o kadar. Yoksa enginliğiyle derinliğiyle o
          Kur’an-ı Kerim’in mütalaa edilmesi lazım.” (“Ramazan ve Kur’an”,
          Bamteli, 21.08.2009)
        </p>
        <br />
        <p className="text-lg text-yellow-600">Çalışma Tanımı:</p>
        <p>
          Bu çalışma sadece 30. cüzü kapsamaktadır ve tahmini tamamlama süresi
          yaklaşık bir yıldır. Müzakereli olacak olan çalışmada katılımcılar
          aşağıdaki kaynak eserlerden veya katkıda bulunmak istediği başka
          kaynak eserlerden en az birinin sorumluluğunu yıl boyunca üstlenip,
          hazırlanarak, müzakere meclisine katılıp, üstlendiği eserden ilgili
          bölümü kendi müktesebatı ile paylaşıp müzakereye açacaktır. Müzakere,
          Hak Dîni Kur'ân Dili Tefsirinin ilgili bölümünün okunmasının üzerine,
          diğer kaynaklardan gerekli görülen paylaşımlarla, derinlemesine anlama
          gayretleri ve diğer tefsirlerin ilgili bölümlerinden farklılıkların
          paylaşılmasıyla devam edecektir. Okuma ve müzakereler esnasında merak
          edilen soru veya düşüncelerle kaynak taraması genişletilebilecek,
          hadis, fıkıh, kelam, akaid, tasavvuf, felsefe konularına ve
          kaynaklarına sıkça başvurularak anlam ve hakikat arayışı
          kuvvetlendirilecektir. Bütün bu gayretler her değerlendirmede
          üstadımız ve hocamızın sözlü ve yazılı eserleriyle de çapraz okuma
          yapmaya çalışılarak, onların Kuran ve Hakikat kanaviçelerinin
          detayları görünmeye çalışılacaktır.
        </p>
        <br />
        <p className="text-lg text-yellow-600">
          Çalışmadaki Tefsirlerin Bir Kısmı:
        </p>
        1- M. Elmalılı Hamdi Yazır (1942), Hak Dîni Kur'ân Dili (9 Cilt).
        <br />
        2- Nâsiruddin Abdullah B. Ömer Beyzâvî'nin(685/-1286) Envâru't-Tenzîl Ve
        Esrâru't-Te'vil (2 Cilt)
        <br />
        3- Rûhu’l-Beyân İsmâil Hakkı Bursevî’nin (Ö. 1137/1725) Tasavvufî
        Tefsiri.
        <br />
        4- Seyyid Kutup (1966), Fî Zilâli'l-Kur'ân (6 Cilt). Asrımızda Yazılmış
        Edebî, Sosyolojik, Psikolojik Derinlikli Kur'ân'ı Anlama Adına Önemli
        Ölçüler Veren Bir Tefsirdir.
        <br />
        5- El-Câmiʿ Li-Aḥkâmi’l-Ḳurʾân Ve’l-Mübeyyin Limâ Teżammenehû
        Mine’s-Sünneti Ve Âyi’l-Furḳān-Muhammed B. Ahmed El-Kurtubî (Ö.
        671/1273)
        <br />
        6- Fahreddin Razi-Mefatihu’l-Gayb, (Ö. 606/1210) <br />
        7- Safvetü’t Tefasir / Tefsirlerin Özü (7 Cilt) Muhammed Ali Es-Sabuni{" "}
        <br />
        8- Câmiu’l-Beyân An Te’vîli Âyi’l-Kur’ân - İbn Cerîr Et-Taberî’nin (Ö.
        310/923) Kur’ân-I Kerîm Tefsiri.
        <br />
        9- Tefsîrü’l-Kur’âni’l-Azîm - Ebü’l-Fidâ İbn Kesîr’in (Ö. 774/1373)
        Kur’ân-I Kerîm Tefsiri.
        <br />
        10- Ebu Hayyan-Bahru’l-Muhit
        <br />
        11- Bediüzzaman Said Nursi-Risale-I Nur Külliyatı
        <br />
        12- M. Fethullah Gülen - Yazılı Ve Sözlü Eserler
        <br />
        <br />
        <p className="text-lg text-yellow-600">Programın Detayları:</p>
        <p>
          <b>Gün:</b> 5 Şubat'tan itibaren Her Pazar sabahı, genel programlar
          dışında.
          <br />
          <b>Saat:</b> 07.00 – 08.30 <br />
          <b>Yer:</b> 8 Montella Place, Prestons
          <br />
        </p>
        <br />
        <br />
        <Divider orientation="left">Katılım Formu</Divider>
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Ad Soyad"
            name="adsoyad"
            rules={[{ required: true }]}
          >
            <Input placeholder="Adiniz ve soyadiniz" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true }, { type: "email" }]}
          >
            <Input placeholder="Email adresiniz" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon={<SendOutlined />} htmlType="submit">
              Gönder
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Tefsir;
