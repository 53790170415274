import { axios } from "./HttpClient";

export const AdminService = {
  getAlerts: () => {
    return axios.get("/alerts");
  },
  newAlert: (values) => {
    return axios.post("/alerts", values);
  },
  deleteAlert: (id) => {
    return axios.delete("/alerts/" + id);
  },

  deleteContact: (id) => {
    return axios.delete("/contacts/" + id);
  },
  getTimesForDate: (value) => {
    return axios.post("/times/newdate", value);
  },
  newProgramTime: (values) => {
    return axios.post("/times", values);
  },
  getLayout: () => {
    return axios.get("/layout");
  },
  newNews: (values) => {
    return axios.post("/news", { ...values });
  },
  editNews: (values) => {
    return axios.put("/news/" + values.id, { ...values });
  },
  getNews: () => {
    return axios.get("/news");
  },
  getNewsHome: () => {
    return axios.get("/news/home");
  },
  deleteNews: (id) => {
    return axios.delete("/news/" + id);
  },
  changeNewsFavorite: (id) => {
    return axios.put("/news/favorite/" + id);
  },
  changeNewsActive: (id) => {
    return axios.put("/news/active/" + id);
  },
  getDashboard: () => {
    return axios.get("/layout/dashboard");
  },
  login: (values, tip) => {
    return axios.post("/staff/login?tip=" + tip, { ...values });
  },
  getFavoriteNews: () => {
    return axios.get("/news/favorite");
  },
  getNewsById: (id) => {
    return axios.get("/news/" + id);
  },
  newEvent: (values) => {
    return axios.post("/events", { ...values });
  },
  getEvents: () => {
    return axios.get("/events");
  },
  editEvents: (values) => {
    return axios.put("/events/" + values.id, { ...values });
  },
  getEventById: (id) => {
    return axios.get("/events/" + id);
  },
  newRegisterMember: (values) => {
    return axios.post("/registers", { ...values });
  },
  deleteRegistrations: (id) => {
    return axios.delete("/registers/" + id);
  },
  getEventsList: () => {
    return axios.get("/events/list");
  },
  getEventRegs: (id) => {
    return axios.get("/events/" + id + "/registrations");
  },
  newSubscription: (values) => {
    return axios.post("/subscriptions", { ...values });
  },
  getSubscriptions: () => {
    return axios.get("/subscriptions");
  },
  deleteSubscriptions: (id) => {
    return axios.delete("/subscriptions/" + id);
  },
  getEventsHome: () => {
    return axios.get("/events/home");
  },
  newContact: (values) => {
    return axios.post("/contacts", { ...values });
  },
  getContacts: () => {
    return axios.get("/contacts");
  },
  changeIsOk: (id) => {
    return axios.put("/contacts/isok/" + id);
  },
  sendEmailToRegs: (values) => {
    return axios.post("/registers/sendemail", { ...values });
  },
  getLogin: (values) => {
    return axios.post("/staff/login", { ...values });
  },
  deleteEvent: (id) => {
    return axios.delete("/events/" + id);
  },
  changeEventFavorite: (id) => {
    return axios.put("/events/favorite/" + id);
  },
  changeEventActive: (id) => {
    return axios.put("/events/active/" + id);
  },
  newSlide: (values) => {
    return axios.post("/layout/slide", { ...values });
  },
  deleteSlide: (id) => {
    return axios.delete("/layout/slide/" + id);
  },
  getNewsPriority: () => {
    return axios.get("/news/priority");
  },
  saveNewPriorities: (value) => {
    return axios.post("/news/priorities", { sira: value });
  },
  getEventsPriority: () => {
    return axios.get("/events/priority");
  },
  saveEventPriorities: (value) => {
    return axios.post("/events/priorities", { sira: value });
  },
  signupMember: (values) => {
    return axios.post("staff/signup", values);
  },
  newProject: (values) => {
    return axios.post("/projects", { ...values });
  },

  getProjects: () => {
    return axios.get("/projects");
  },
  getFavoriteProjects: () => {
    return axios.get("/projects/favorites");
  },
  getProjectById: (id) => {
    return axios.get("/projects/" + id);
  },
  getProjectByIdForDonationsForm: (id) => {
    return axios.get("/projects/" + id + "/form");
  },

  getProjectByType: (id) => {
    return axios.get("/projects/type/" + id);
  },
  finalizeOrder: (values) => {
    return axios.post("/donations/sendFinalOrder", values);
  },
  getShortUrl: (longurl) => {
    return axios.get("/cuttly?longurl=" + longurl);
  },
  newSmsEvent: (values) => {
    return axios.post("/sms", values);
  },
  getSmsEvents: () => {
    return axios.get("/sms");
  },
  deleteSmsEvent: (id) => {
    return axios.delete("/sms/event/" + id);
  },
  deleteSmsMessage: (id) => {
    return axios.delete("/sms/message/" + id);
  },
  newUnubscription: (values) => {
    return axios.post("/cikis", values);
  },
  getUnubscriptions: () => {
    return axios.get("/cikis");
  },
  deleteStaffTel: (id) => {
    return axios.delete("/staff/" + id + "/deletephone");
  },
  newMentor: (values) => {
    return axios.post("/staff/newmentor", values);
  },
  NewMutevelli: (values) => {
    return axios.post("/mentors/newmutevelli", values);
  },
  getMutevellis: (id) => {
    return axios.get("/mentors/" + id + "/mutevelliler");
  },
  deleteMutevelli: (id) => {
    return axios.delete("/mentors/mutevelli/" + id);
  },
  getRaporlar: (values) => {
    console.log(values);
    return axios.post("/mentors/reports", values);
  },
  newReport: (values) => {
    return axios.post("/mentors/newreport", values);
  },
  getMentors: () => {
    return axios.get("/mentors/list");
  },
  mentorReports: (id) => {
    return axios.get("/mentors/" + id + "/mentorreports");
  },
  adminSonAltiAy: () => {
    return axios.get("/mentors/sonaltiay");
  },
  getEnsonRaporu: () => {
    return axios.get("/mentors/enson");
  },
  getToplamPuanlar: () => {
    return axios.get("/mentors/puanlar");
  },
  getCampActypes: () => {
    return axios.get("/camp/actypes");
  },
  newCampAcType: (v) => {
    return axios.post("/camp/newactype", v);
  },
  campMembers: () => {
    return axios.get("/camp/members");
  },
  acChoices: () => {
    return axios.get("/camp/choices");
  },
  getSalavats: () => {
    return axios.get("/salavat");
  },
  newSalavats: (v) => {
    return axios.post("/salavat", v);
  },
  getSonAyRaporu: () => {
    return axios.get("/mentors/sonay");
  },
  newTefsir: (v) => {
    return axios.post("/registers/tefsir", v);
  },
  getTefsir: () => {
    return axios.get("/registers/tefsir");
  },
  getMentorKeyfiyets: (id) => {
    return axios.get("/keyfiyet/" + id);
  },
  newKeyfiyet: (v) => {
    return axios.post("/keyfiyet", v);
  },
  getMyWeek: (mentorId, week) => {
    return axios.get(
      "/keyfiyet/getmyweek?mentorId=" + mentorId + "&week=" + week
    );
  },
  getKeyfiyetRaporu: (weeks) => {
    return axios.post("/keyfiyet/rapor", { weeks });
  },
};
