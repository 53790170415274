import { Col, Row } from "antd";
import React from "react";

const LinkBar = () => {
  return (
    <div className="mx-4">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <img
            onClick={() =>
              window.open("https://pc.galaxyfoundation.org.au/", "_blank")
            }
            src={require("../../assets/images/pc-banner.jpg").default}
            alt="pc-banner"
            className="border border-gray-300 shadow-md cursor-pointer"
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <img
            onClick={() => window.open("https://nexted.net.au/", "_blank")}
            src={require("../../assets/images/fwb-banner.jpg").default}
            alt="fwb-banner"
            className="border border-gray-300 shadow-md cursor-pointer"
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <img
            onClick={() =>
              window.open("https://livingthequranacademy.com.au/", "_blank")
            }
            src={require("../../assets/images/airk.png").default}
            alt="si-banner"
            className="border border-gray-300 shadow-md cursor-pointer"
          />
        </Col>
      </Row>
    </div>
  );
};

export default LinkBar;
