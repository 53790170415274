import { HeartOutlined } from "@ant-design/icons";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Button,
  Form,
  Input,
  InputNumber,
  notification,
  Radio,
  Space,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const DonationForm = ({ pid }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState(0);
  const stripe = useStripe();
  const elements = useElements();
  const router = useHistory();
  const [loading, setLoading] = useState(false);
  const { lg } = useBreakpoint();

  useEffect(() => {
    form.setFieldsValue({ total: value });
  }, [value]);

  const handleSubmit = useCallback(
    async function (values) {
      //    event.preventDefault();
      console.log(values);
      setLoading(true);
      if (!stripe || !elements) {
        setLoading(false);
        return;
      }

      try {
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        if (error) {
          setLoading(false);
          return;
        }

        const obj = {
          ...values,
          projectId: pid,
          age18: true,
        };
        console.log("obj:", obj);
        let response = await AdminService.finalizeOrder({
          ...obj,
          payment: {
            payment_method_id: paymentMethod.id,
          },
        });
        // 3D Secure
        while (response.status === 402) {
          const { paymentIntent, error } = await stripe.handleCardAction(
            response.data.token
          );
          if (error) {
            setLoading(false);
            // unable to pass 3Ds
            return;
          }

          response = await AdminService.finalizeOrder({
            ...obj,
            payment: {
              payment_intent_id: paymentIntent.id,
            },
          });
        }

        const ok = response.status === 200 || response.status === 204;
        if (ok) {
          notification.success({
            message: "Success!",
            description: "Thank you for your payment!",
          });

          form.resetFields();
          router.replace("/home/step7");
        } else {
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    },
    [stripe, elements, form, router]
  );
  return (
    <div className="bg-eeeeee">
      <div className="mx-auto max-w-7xl p-2">
        <div>
          <h1
            className="text-blue-900 font-extrabold  uppercase mb-1 text-center"
            style={{ fontSize: "44px" }}
          >
            DONATION FORM
          </h1>

          <br />
          <br />
          <Form
            form={form}
            size="large"
            {...GlobalVariables.formLayout}
            onFinish={handleSubmit}
            initialValues={{ total: 0 }}
          >
            <Form.Item
              label="First Name"
              name="firstname"
              rules={[{ required: true, message: "First name is required!" }]}
            >
              <Input placeholder="Please type your firstname" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastname"
              rules={[{ required: true, message: "Last name is required!" }]}
            >
              <Input placeholder="Please type your lastname" />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[{ required: true, message: "Email is required!" }]}
            >
              <Input placeholder="Please type your email" />
            </Form.Item>
            <Form.Item label="Your phone (opt.)" name="phone">
              <Input placeholder="Please type your phone" />
            </Form.Item>

            <Form.Item
              label={<span>Your Donation</span>}
              className="-mt-1"
              name="total"
            >
              <Space align="start">
                <Radio.Group
                  value={value}
                  buttonStyle="solid"
                  style={{ marginBottom: 16 }}
                  onChange={(a) => setValue(a.target.value)}
                >
                  {[50, 100, 250, 500].map((s) => (
                    <Radio.Button key={s} value={s}>
                      ${s}
                    </Radio.Button>
                  ))}
                </Radio.Group>
                <Space direction="vertical">
                  <InputNumber
                    style={{ width: "200px" }}
                    min={20}
                    max={10000}
                    placeholder="Or type your value"
                    onChange={(a) => setValue(a)}
                    value={value}
                    addonBefore="$"
                  />
                  <span>Or Type Yor Value</span>
                </Space>
              </Space>
            </Form.Item>
            {lg ? (
              <Form.Item {...GlobalVariables.tailLayout}>
                <h4 className="text-base">
                  Please Type Your Credit/Debit Card Details
                </h4>
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: "18px",
                        fontFamily: "monospace",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "red",
                      },
                    },
                  }}
                  className="st-form"
                />
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  disabled={!stripe || !elements}
                  block
                  style={{ maxWidth: "500px" }}
                  size="large"
                >
                  <HeartOutlined /> Donate Now
                </Button>
              </Form.Item>
            ) : (
              <Form.Item>
                <h4 className="text-base">
                  Please Type Your Credit/Debit Card Details
                </h4>
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: "18px",
                        fontFamily: "monospace",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "red",
                      },
                    },
                  }}
                  className="st-form"
                />
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  disabled={!stripe || !elements}
                  block
                  style={{ maxWidth: "500px" }}
                  size="large"
                >
                  <HeartOutlined /> Donate Now
                </Button>
              </Form.Item>
            )}
          </Form>
          <img
            src={require("../../assets/images/secure-stripe.png").default}
            alt="secure"
            className="w-full max-w-full"
          />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default DonationForm;
