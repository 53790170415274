import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

const LoginPage = () => {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const router = useHistory();
  const location = useLocation();
  const params = useQuery();
  const [bloading, setBloading] = useState(false);
  const { from } = location.state || { from: { pathname: "/" } };
  const onFinish = (v) => {
    console.log(v);

    var tip = params.get("type") === null ? "portal" : params.get("type");

    AdminService.login(v, tip).then((data) => {
      console.log(data.data);
      localStorage.setItem("token", data.data.token);
      if (data.data.user?.role === "Admin") {
        localStorage.setItem("user", JSON.stringify(data.data.user));
        from.pathname === "/" ? router.push("/admin") : router.replace(from);
      } else {
        localStorage.setItem("user", JSON.stringify(data.data.mentor));

        from.pathname === "/"
          ? router.push("/mentor/raporlar")
          : router.replace(from);
      }
    });
  };
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <div className="login-page" style={{ minWidth: "320px", maxWidth: "95%" }}>
      <div
        className=" rounded-lg shadow-lg flex items-center justify-center bg-gray-50 py-4 px-4 sm:px-6 lg:px-8 "
        style={{ minWidth: "320px", maxWidth: "95%" }}
      >
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-16 w-auto"
              src={require("../../assets/images/logo.png").default}
              alt="Logo"
            />
            <h2 className="mt-6 text-center text-xl font-extrabold text-blue-600">
              Sign in to your account
            </h2>
          </div>
          <Form className="mt-8 space-y-6" onFinish={onFinish}>
            <Form.Item name="email">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Username
                </label>
                <input
                  id="email-address"
                  name="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 sm:text-sm"
                  placeholder="Type Username"
                />
              </div>
            </Form.Item>
            <Form.Item name="password">
              <div className="-mt-10">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </Form.Item>

            <Space className="w-full" direction="vertical">
              <Button
                loading={bloading}
                htmlType="submit"
                type="primary"
                block
                icon={<LockOutlined />}
              >
                Sign in
              </Button>
              <Button onClick={() => router.push("/")} block>
                Go to homepage
              </Button>
            </Space>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
