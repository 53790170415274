import {
  CalendarOutlined,
  DownOutlined,
  HomeOutlined,
  IdcardOutlined,
  MailOutlined,
  MenuOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { Button, Col, Drawer, Dropdown, Layout, Menu, Row, Space } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import AboutPage from "src/components/about/AboutPage";
import EventDetails from "src/components/admin/events/EventDetails";
import NewsDetails from "src/components/admin/news/NewsDetails";
import ContactForm from "src/components/home/ContactForm";
import EventsPage from "src/components/home/EventsPage";
import FooterWidget from "src/components/home/FooterWidget";
import NewsPage from "src/components/home/NewsPage";
import ProjectDetails from "src/components/home/ProjectDetails";
import ProjectsPage from "src/components/home/ProjectsPage";
import Salavat from "src/components/home/Salavat";
import Tefsir from "src/components/home/Tefsir";
import Unsubscribe from "src/components/home/Unsubscribe";
import { AdminService } from "src/resources/AdminService";
import HomePage from "../components/home/HomePage";

const HomeLayout = () => {
  const { Header, Content } = Layout;
  const location = useLocation();
  const { lg } = useBreakpoint();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [actives, setActives] = useState([location.pathname.split("/").pop()]);
  const router = useHistory();
  const [initialRender, setInitialRender] = useState(true);
  const [layout, setLayout] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getLayout().then((data) => {
        console.log("layout:", data.data);
        setLayout(data.data);
      });
    }
  }, [initialRender]);
  const loginClick = (e) => {
    router.push("/login/loginpage?type=" + e.key);
  };
  const loginMenu = (
    <Menu onClick={loginClick}>
      <Menu.Item key="portal">Staff Portal</Menu.Item>
      <hr />
      <Menu.Item key="admin">Site Admin</Menu.Item>
    </Menu>
  );

  const menuClick = (e) => {
    router.push("/home/" + e.key);
    setActives([e.key]);
    setIsCollapsed(false);
  };
  const MainMenu = () => (
    <Menu
      className="main-menum"
      mode={lg ? "horizontal" : "inline"}
      defaultSelectedKeys={actives}
      onClick={menuClick}
    >
      <Menu.Item key="homepage" icon={<HomeOutlined />}>
        Home
      </Menu.Item>
      <Menu.Item key="about" icon={<IdcardOutlined />}>
        About
      </Menu.Item>

      <Menu.Item key="projects" icon={<CalendarOutlined />}>
        Projects
      </Menu.Item>
      <Menu.Item key="events" icon={<CalendarOutlined />}>
        Events
      </Menu.Item>
      <Menu.Item key="news" icon={<NotificationOutlined />}>
        News
      </Menu.Item>
      <Menu.Item key="contact" icon={<MailOutlined />}>
        Contact
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout className="layout overflow-hidden">
      <Header
        style={{
          position: lg ? "fixed" : "unset",
          zIndex: 111,
          width: "100%",
        }}
      >
        <div className="bg-blueGray-100 -mx-4 border-b border-gray-300">
          <div className="container mx-auto">
            <Row justify="space-between" align="middle" className="mx-4">
              <Col>
                <img
                  src={require("../assets/images/logo.png").default}
                  width="220"
                  alt="logo"
                  className="mt-2"
                />
              </Col>
              {lg && (
                <Col>
                  <span className="text-blueGray-600 font-medium italic text-lg">
                    Building a brighter future...
                  </span>
                </Col>
              )}
              <Col style={{ textAlign: "right" }}>
                {lg && (
                  <Space>
                    {/* <Button>{user.fullname}</Button> */}
                    <Dropdown overlay={loginMenu} trigger={["click"]}>
                      <Button type="primary">
                        <Space>
                          Login
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                    {/* <Button
                      type="primary"
                      size="large"
                      icon={<ScheduleOutlined />}
                      onClick={() => router.push("/home/projects")}
                    >
                      Donate Regularly
                    </Button> */}
                  </Space>
                )}

                {!lg && (
                  <Button
                    style={{ marginLeft: "5px" }}
                    icon={<MenuOutlined />}
                    size="large"
                    onClick={() => setIsCollapsed(true)}
                  />
                )}
              </Col>
            </Row>
          </div>
          {lg && (
            <Row>
              <Col span={24}>
                <MainMenu />
              </Col>
            </Row>
          )}
        </div>
      </Header>
      <Content
        style={{
          padding: "0",
          marginTop: lg ? "115px" : "unset",
        }}
      >
        <Drawer
          title={
            <img
              src={require("../assets/images/logo.png").default}
              width="150"
              alt="logo"
            />
          }
          visible={isCollapsed}
          placement="left"
          closable={true}
          onClose={() => setIsCollapsed(false)}
        >
          <MainMenu />
        </Drawer>
        {layout && (
          <div className="bg-eeeeee pb-6">
            <div className="container mx-auto border-r border-l border-gray-300 bg-white shadow-lg">
              <Switch>
                <Route path="/home" exact>
                  <Redirect to="/home/homepage" />
                </Route>
                <Route path="/home/homepage">
                  <HomePage layout={layout} />
                </Route>
                <Route path="/home/projects/:id" component={ProjectDetails} />
                <Route path="/home/projects" component={ProjectsPage} />
                <Route path="/home/contact" component={ContactForm} />
                <Route path="/home/about" component={AboutPage} />
                <Route path="/home/unsubscribe" component={Unsubscribe} />
                <Route path="/home/tefsir" component={Tefsir} />

                {/* <Route path="/home/mission" component={Mission} /> */}
                <Route
                  path="/home/events/:id/details"
                  component={EventDetails}
                />
                <Route path="/home/events" component={EventsPage} />
                <Route path="/home/news/:id/details" component={NewsDetails} />
                <Route path="/home/news" component={NewsPage} />
                <Route path="/home/salavat" component={Salavat} />

                <Route>
                  <Redirect to="/home" />
                </Route>
              </Switch>
            </div>
          </div>
        )}
      </Content>
      <div style={{ textAlign: "center" }} className="bg-eeeeee">
        <FooterWidget />
      </div>
    </Layout>
  );
};

export default HomeLayout;
