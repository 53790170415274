import { Spin } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import SliderBar from "../admin/banner/SliderBar";
import AboutBar from "./AboutBar";
import EventBanner from "./EventBanner";
import EventsBar from "./EventsBar";
import Features from "./Features";
import LinkBar from "./LinkBar";
import NewsBar from "./NewsBar";
import ProjectsBar from "./ProjectsBar";

import SocialBanner from "./SocialBanner";
import SpecsBanner from "./SpecsBanner";

const HomePage = ({ layout }) => {
  const { sm } = useBreakpoint();
  return (
    <div>
      <Spin spinning={layout === null}>
        <img
          src={require("../../assets/images/fg.jpg").default}
          alt="fg"
          style={{ width: "100%" }}
        />
        {/* {layout && layout.slides.length > 0 && (
          <SliderBar slides={layout.slides} />
        )} */}
        <br />
        <LinkBar />
        <br />
        <Features />
        {layout && layout.events.isThere && (
          <EventBanner item={layout.events.data} />
        )}
      </Spin>
      <br />
      <ProjectsBar />
      <br />
      <NewsBar />
      <br />
      <EventsBar />
      <AboutBar />
      {/* <SocialBanner /> */}
      <SpecsBanner />
    </div>
  );
};

export default HomePage;
