import {
  Form,
  Input,
  Checkbox,
  Space,
  Button,
  notification,
  InputNumber,
} from "antd";

import React, { useState } from "react";
import { AdminService } from "../../resources/AdminService";

const RegForm = ({ smv, eventId, isCount, isMessage, regMessage }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    values = { ...values, eventId: eventId };
    AdminService.newRegisterMember(values)
      .then((data) => {
        notification.success({
          message: "Success",
          description: "Registration completed successfully",
        });
        setLoading(false);
        onReset();
        smv(false);
      })
      .catch(() => {
        setLoading(false);
        onReset();
        smv(false);
      });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        layout="vertical"
        initialValues={{ subs: true, adult: 1, child: 0 }}
      >
        <Form.Item
          name="name"
          label="Your Name"
          rules={[{ required: true, message: "Name required" }]}
        >
          <Input placeholder="Please type your name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Your E-mail"
          rules={[{ required: true, message: "E-mail required" }]}
        >
          <Input
            placeholder="Please type your email"
            type="email"
            autoComplete="email"
          />
        </Form.Item>
        <Form.Item name="phone" label="Your Phone">
          <Input placeholder="Please type your phone" />
        </Form.Item>
        {regMessage && (
          <Form.Item>
            <span>{regMessage}</span>
          </Form.Item>
        )}
        {isMessage && (
          <Form.Item name="message" label="Your Message">
            <Input placeholder="Please type your message" />
          </Form.Item>
        )}
        {isCount && (
          <Form.Item name="adult" label="Adult">
            <InputNumber min={0} max={10} />
          </Form.Item>
        )}
        {isCount && (
          <Form.Item name="child" label="Child">
            <InputNumber min={0} max={10} />
          </Form.Item>
        )}
        <Form.Item name="subs" valuePropName="checked">
          <Checkbox>Add my mail to newsletter list</Checkbox>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              Register
            </Button>
            <Button type="default" htmlType="reset">
              Clear
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegForm;
