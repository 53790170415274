import { ArrowRightOutlined, CalendarOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Link, useHistory } from "react-router-dom";
import { AdminService } from "../../resources/AdminService";

const NewsBar = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [news, setNews] = useState(null);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getFavoriteNews().then((data) => {
        setNews(data.data);
      });
    }
  }, [initialRender]);

  const imajVer = (imajlar) => {
    const imaj =
      imajlar.length > 0 && imajlar.filter((i) => i.isTop === true).pop();
    return imaj === undefined ? false : imaj.url;
  };
  const videoVer = (videolar) => {
    const video =
      videolar.length > 0 && videolar.filter((i) => i.isTop === true).pop();
    return video === undefined ? false : video.url;
  };

  return (
    news && (
      <div>
        <div
          className="relative    pt-16 pb-10 px-4 sm:px-6 lg:pt-4 lg:pb-8 lg:px-8 bg-gradient-to-b from-white to-gray-100 border-gray-300 border-b"
          //  style={{ backgroundColor: "rgba(216, 202, 186,0.4)" }}
        >
          <div className="absolute inset-0">
            <div className=" h-1/3 sm:h-2/3" />
          </div>
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
              <h2 className="text-3xl tracking-tight font-extrabold text-sky-500 sm:text-4xl">
                Latest News from GALAXY
              </h2>
              <p className="mt-3 max-w-3xl mx-auto text-xl text-gray-500 sm:mt-4">
                You can get the latest news from GALAXY and videos of past
                events in this section.
              </p>
            </div>
            <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
              {news.map((n) => (
                <div
                  key={n.id}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="flex-shrink-0">
                    {imajVer(n.images) && (
                      <img
                        className="h-53 w-full object-cover"
                        src={
                          process.env.REACT_APP_FILES_BASEURL +
                          imajVer(n.images)
                        }
                        alt=""
                      />
                    )}

                    {!imajVer(n.images) && videoVer(n.videos) && (
                      <div className="player-wrapper">
                        <ReactPlayer
                          url={videoVer(n.videos)}
                          className="react-player"
                          width="100%"
                          height="100%"
                          controls={true}
                        />
                      </div>
                    )}
                    {!imajVer(n.images) && !videoVer(n.videos) && (
                      <img
                        className="h-52 w-full object-cover"
                        src={require("../../assets/images/news.png").default}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="absolute  p-0.5 m-3  bg-cyan-100 border rounded-sm border-cyan-200">
                    News
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-cyan-600">
                        <CalendarOutlined />{" "}
                        {moment(n.createdOn).format("MMM DD, dddd")}
                      </p>
                      <Link
                        to={`/home/news/${n.id}/details`}
                        className="block mt-2"
                      >
                        <p className="text-xl font-semibold text-gray-900">
                          {n.title}
                        </p>
                        <p className="mt-3 text-base text-gray-500">
                          {n.description}
                        </p>
                      </Link>
                    </div>
                    <div className="mt-6 flex items-center">
                      <div className="flex-shrink-0">
                        <Button
                          onClick={() =>
                            router.push(`/home/news/${n.id}/details`)
                          }
                          type="primary"
                          icon={<ArrowRightOutlined />}
                        >
                          Get Details
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mx-auto text-center mt-10">
              <Button
                type="primary"
                size="large"
                onClick={() => router.push("/home/news")}
                icon={<ArrowRightOutlined />}
              >
                Get All News
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default NewsBar;
