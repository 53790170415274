import { DatePicker, Divider, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { AdminService } from "src/resources/AdminService";

const KeyfiyetRaporu = () => {
  const [veri, setVeri] = useState(null);
  const [total, setTotal] = useState(null);
  const changeDates = (a, b, c) => {
    console.log(a);
    console.log(b);
    console.log(c);
    let startDate = a[0].startOf("week").format("YYYY-MM-DD");
    let endDate = a[1].startOf("week").format("YYYY-MM-DD");

    const weeks = [];
    // while (startDate <= endDate) {
    //   weeks.push({
    //     start: startDate,
    //     end: moment(startDate).add(6, "days").format("YYYY-MM-DD"),
    //     week: moment(startDate).format("YYYY-wo"),
    //   });
    //   startDate = moment(startDate).add(7, "days").format("YYYY-MM-DD");
    // }
    while (startDate <= endDate) {
      weeks.push(moment(startDate).format("YYYY-wo"));
      startDate = moment(startDate).add(7, "days").format("YYYY-MM-DD");
    }
    console.log("weeks:", weeks);
    AdminService.getKeyfiyetRaporu(weeks).then((data) => {
      console.log(data.data);
      setVeri(data.data.rapor);
      setTotal(data.data.total);
    });
  };
  const columns = [
    {
      title: "Mentor",
      dataIndex: "name",
    },
    {
      title: "Kuran",
      dataIndex: "rapor",
      render: (rapor) => <span>{rapor?.kuran}</span>,
      className: "text-center",
    },
    {
      title: "Cevsen",
      dataIndex: "rapor",
      render: (rapor) => <span>{rapor?.cevsen}</span>,
      className: "text-center",
    },
    {
      title: "Risale",
      dataIndex: "rapor",
      render: (rapor) => <span>{rapor?.risale}</span>,
      className: "text-center",
    },
    {
      title: "Pirlanta",
      dataIndex: "rapor",
      render: (rapor) => <span>{rapor?.pirlanta}</span>,
      className: "text-center",
    },
    {
      title: "Oruc",
      dataIndex: "rapor",
      render: (rapor) => <span>{rapor?.oruc}</span>,
      className: "text-center",
    },
    {
      title: "Teheccud",
      dataIndex: "rapor",
      render: (rapor) => <span>{rapor?.teheccud}</span>,
      className: "text-center",
    },
  ];
  const columns2 = [
    {
      title: "Mentor",
      dataIndex: "key",
    },
    {
      title: "Kuran",
      dataIndex: "kuran",
      className: "text-center",
    },
    {
      title: "Cevsen",
      dataIndex: "cevsen",
      className: "text-center",
    },
    {
      title: "Risale",
      dataIndex: "risale",
      className: "text-center",
    },
    {
      title: "Pirlanta",
      dataIndex: "pirlanta",
      className: "text-center",
    },
    {
      title: "Oruc",
      dataIndex: "oruc",
      className: "text-center",
    },
    {
      title: "Teheccud",
      dataIndex: "teheccud",
      className: "text-center",
    },
  ];
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current > moment().endOf("week");
  };
  return (
    <div className="max-w-3xl">
      <Divider orientation="left">Keyfiyet Raporu</Divider>
      <p>Tarih araligi seciniz</p>
      <DatePicker.RangePicker
        disabledDate={disabledDate}
        picker="week"
        onChange={changeDates}
      />
      <br />
      <br />
      {total && (
        <Table
          className="mb-6"
          title={() => (
            <span className="text-yellow-600 font-bold">
              Toplam Rapor (Bu haftalar icin)
            </span>
          )}
          rowKey="key"
          dataSource={total}
          bordered
          size="small"
          pagination={false}
          columns={columns2}
        />
      )}
      <Divider orientation="left">Hafta Bazli Rapor</Divider>
      {veri &&
        veri.length > 0 &&
        veri.map((item, index) => (
          <Table
            className="mb-6"
            title={() => (
              <>
                <span>{item.key} </span>
                <span className="text-xs text-gray-400">
                  (
                  {moment(item.veri[0].rapor.date)
                    .startOf("week")
                    .isoWeekday("Monday")
                    .format("DD MMM") +
                    " - " +
                    moment(item.veri[0].rapor.date)
                      .startOf("week")
                      .isoWeekday("Sunday")
                      .format("DD MMM")}
                  )
                </span>
              </>
            )}
            key={index}
            rowKey="key"
            dataSource={veri[index].veri}
            bordered
            size="small"
            pagination={false}
            columns={columns}
          />
        ))}
    </div>
  );
};

export default KeyfiyetRaporu;
