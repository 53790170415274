import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const Choices = () => {
  const [choices, setChoices] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.acChoices().then((data) => {
        console.log(data.data);
        setChoices(data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Accommodation Type",
      dataIndex: "key",
    },
    {
      title: "Choice 1",
      dataIndex: "data",
      render: (data) => data.filter((d) => d.key === 1)[0]?.member?.length || 0,
    },
    {
      title: "Choice 2",
      dataIndex: "data",
      render: (data) => data.filter((d) => d.key === 2)[0]?.member?.length || 0,
    },
    {
      title: "Choice 3",
      dataIndex: "data",
      render: (data) => data.filter((d) => d.key === 3)[0]?.member?.length || 0,
    },
  ];

  const columns2 = [
    {
      title: "Accommodation Type",
      dataIndex: "key",
    },
    {
      title: "Choice 1",
      dataIndex: "data",
      render: (data) =>
        data
          .filter((d) => d.key === 1)
          ?.map((a) => a.member.map((b) => <li>- {b}</li>)),
    },
    {
      title: "Choice 2",
      dataIndex: "data",
      render: (data) =>
        data
          .filter((d) => d.key === 2)
          ?.map((a) => a.member.map((b) => <li>- {b}</li>)),
    },
    {
      title: "Choice 3",
      dataIndex: "data",
      render: (data) =>
        data
          .filter((d) => d.key === 3)
          ?.map((a) => a.member.map((b) => <li>- {b}</li>)),
    },
  ];

  return (
    <div className="max-w-2xl">
      <Table
        dataSource={choices}
        columns={columns}
        bordered
        size="small"
        pagination={false}
        rowKey="key"
        loading={choices === 0}
      />
      <br />
      <Table
        dataSource={choices}
        columns={columns2}
        bordered
        size="small"
        pagination={false}
        rowKey="key"
        loading={choices === 0}
      />
    </div>
  );
};

export default Choices;
