import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  notification,
  PageHeader,
  Popconfirm,
  Space,
  Switch,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../../resources/AdminService";
import NewEventsForm from "./NewEventsForm";

const EventsAdmin = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [events, setEvents] = useState(null);
  const [newPanel, setNewPanel] = useState(false);
  const router = useHistory();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getEvents().then((data) => {
        setEvents(data.data);
      });
    }
  }, [initialRender]);

  const activeChanged = (a) => {
    AdminService.changeEventActive(a).then((data) => {
      setInitialRender(true);
      notification.success({
        message: "Success!",
        description: "Event updated",
      });
    });
  };

  const favoriteChanged = (a) => {
    AdminService.changeEventFavorite(a).then((data) => {
      setInitialRender(true);
      notification.success({
        message: "Success!",
        description: "Event updated",
      });
    });
  };
  const goDetails = (veri) => {
    router.push(`/admin/events/${veri.key}/details`, { veri });
  };
  const goEdit = (veri) => {
    router.push(`/admin/events/${veri.key}/edit`, { veri });
  };
  const deleteEvent = (id) => {
    AdminService.deleteEvent(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Your Event has been deleted successfully",
      });
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Date/Time",
      dataIndex: "events",
      render: (events) => moment(events.date).format("DD-MM-YY ddd, HH:mm"),
    },
    {
      title: "Title",
      dataIndex: "events",
      render: (events) => events.title,
    },
    {
      title: "Short Description",
      dataIndex: "events",
      render: (events) => events.description,
    },
    {
      title: "is Active?",
      dataIndex: "events",
      render: (events) => (
        <Switch
          checked={events.isActive}
          onChange={() => activeChanged(events.id)}
        />
      ),
    },
    {
      title: "is Favorite?",
      dataIndex: "events",
      render: (events) => (
        <Switch
          checked={events.isFavorite}
          onChange={() => favoriteChanged(events.id)}
        />
      ),
    },
    {
      title: "Actions",
      render: (events) => (
        <Space>
          <Tooltip title="Event Details" placement="left" key={events.id}>
            <Button
              type="primary"
              onClick={() => goDetails(events)}
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip title="Edit Events" placement="left" key={events.id}>
            <Button
              type="default"
              onClick={() => goEdit(events)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Delete Event" placement="left" key={events.title}>
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => deleteEvent(events.key)}
              okText="Yes! Delete."
              cancelText="Cancel"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div className="max-w-5xl">
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title={newPanel ? "Add New Event" : "Events List"}
        extra={[
          <Button
            key="a"
            onClick={() => setNewPanel(!newPanel)}
            icon={newPanel ? <CloseOutlined /> : <PlusOutlined />}
            type="primary"
            danger={newPanel}
          >
            {newPanel ? "Cancel" : "Add Event"}
          </Button>,
        ]}
      />
      {!newPanel && (
        <Table
          scroll={{ x: 600 }}
          loading={events === null}
          dataSource={events}
          bordered
          columns={columns}
          size="small"
        />
      )}
      {newPanel && (
        <NewEventsForm sir={setInitialRender} newPanel={setNewPanel} />
      )}
    </div>
  );
};

export default EventsAdmin;
