import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  notification,
  Row,
  Space,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const Keyfiyet = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [form] = Form.useForm();
  const [date, setDate] = useState(null);
  const [week, setWeek] = useState(null);
  const [veri, setVeri] = useState(null);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getMentorKeyfiyets(
        JSON.parse(localStorage.getItem("user")).id
      ).then((data) => {
        console.log("veri :", data.data);
        setVeri(data.data);
      });
    }
  }, [initialRender]);

  const onFinish = (v) => {
    v = {
      ...v,
      date,
      week,
      hafta: null,
      mentorId: JSON.parse(localStorage.getItem("user")).id,
    };
    console.log(v);
    AdminService.newKeyfiyet(v).then((data) => {
      notification.success({ message: "Kaydedildi" });
      setInitialRender(true);
    });
    onReset();
  };
  const onReset = () => {
    form.resetFields();
  };
  const dateChanged = (a, b) => {
    form.resetFields();
    console.log(a, b);
    AdminService.getMyWeek(JSON.parse(localStorage.getItem("user")).id, b).then(
      (data) => {
        console.log(data.data);
        setDate(a.format());
        setWeek(b);

        form.setFieldsValue({ ...data.data, hafta: a });
      }
    );
  };

  const columns = [
    {
      title: "Hafta",
      render: (item) => (
        <>
          <span>{item.key} </span>
          <span className="text-xs text-gray-400">
            (
            {moment(item.veri[0].date)
              .startOf("week")
              .isoWeekday("Monday")
              .format("DD MMM") +
              " - " +
              moment(item.veri[0].date)
                .startOf("week")
                .isoWeekday("Sunday")
                .format("DD MMM")}
            )
          </span>
        </>
      ),
    },
    {
      title: "Kuran",
      dataIndex: "veri",
      render: (veri) => <span>{veri[0].kuran}</span>,
      className: "text-center",
    },
    {
      title: "Cevsen",
      dataIndex: "veri",
      render: (veri) => <span>{veri[0].cevsen}</span>,
      className: "text-center",
    },
    {
      title: "Risale",
      dataIndex: "veri",
      render: (veri) => <span>{veri[0].risale}</span>,
      className: "text-center",
    },
    {
      title: "Pirlanta",
      dataIndex: "veri",
      render: (veri) => <span>{veri[0].pirlanta}</span>,
      className: "text-center",
    },
    {
      title: "Oruc",
      dataIndex: "veri",
      render: (veri) => <span>{veri[0].oruc}</span>,
      className: "text-center",
    },
    {
      title: "Teheccud",
      dataIndex: "veri",
      render: (veri) => <span>{veri[0].teheccud}</span>,
      className: "text-center",
    },
  ];
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current > moment().endOf("week");
  };
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Divider orientation="left">Yeni Keyfiyet Girisi</Divider>
          <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            {...GlobalVariables.formLayout}
          >
            <Form.Item name="id" hidden />
            <Form.Item
              label="Hafta Seciniz"
              name="hafta"
              rules={[{ required: true }]}
            >
              <DatePicker
                disabledDate={disabledDate}
                onChange={dateChanged}
                picker="week"
                className="w-32"
              />
            </Form.Item>
            <Form.Item
              className="!mb-1"
              name="kuran"
              label="Kuran"
              help={<span className="text-xs">Haftalik Sayfa Sayisi</span>}
            >
              <InputNumber min={0} max={600} className="w-32" />
            </Form.Item>
            <Form.Item
              className="!mb-1"
              name="cevsen"
              label="Cevsen"
              help={<span className="text-xs">Haftalik Bab Sayisi</span>}
            >
              <InputNumber min={0} max={600} className="w-32" />
            </Form.Item>
            <Form.Item
              className="!mb-1"
              name="risale"
              label="Risale"
              help={<span className="text-xs">Haftalik Sayfa Sayisi</span>}
            >
              <InputNumber min={0} max={600} className="w-32" />
            </Form.Item>
            <Form.Item
              className="!mb-1"
              name="pirlanta"
              label="Pirlanta"
              help={<span className="text-xs">Haftalik Sayfa Sayisi</span>}
            >
              <InputNumber min={0} max={600} className="w-32" />
            </Form.Item>
            <Form.Item
              className="!mb-1"
              name="oruc"
              label="Oruc"
              help={<span className="text-xs">Haftalik Gun Sayisi</span>}
            >
              <InputNumber min={0} max={600} className="w-32" />
            </Form.Item>
            <Form.Item
              className="!mb-1"
              name="teheccud"
              label="Teheccud"
              help={<span className="text-xs">Haftalik Gun Sayisi</span>}
            >
              <InputNumber min={0} max={600} className="w-32" />
            </Form.Item>
            <Form.Item {...GlobalVariables.tailLayout}>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Kaydet
                </Button>
                <Button htmlType="reset" icon={<ClearOutlined />}>
                  Clear
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Divider orientation="left">Haftalik Keyfiyet Raporu</Divider>
          <Table
            dataSource={veri}
            columns={columns}
            size="small"
            bordered
            pagination={false}
            loading={veri === null}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Keyfiyet;
