import {
  EnvironmentOutlined,
  HeartOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, notification, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AdminService } from "../../resources/AdminService";

const FooterWidget = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    AdminService.newSubscription(values)
      .then((data) => {
        notification.success({
          message: "Thank you.",
          description: "Subscription completed successfully!",
        });
        setLoading(false);
        form.resetFields();
      })
      .catch((data) => {
        setLoading(false);
      });
  };

  return (
    <div className="bg-gradient-to-b from-sky-900 to-sky-800 ">
      <footer className=" p-8  mt-5 " aria-labelledby="footer-heading">
        <div className="container mx-auto max-w-7xl">
          <Row gutter={[24, 54]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="space-y-8 xl:col-span-1">
                <img
                  className="h-20"
                  src={require("../../assets/images/logo-white.png").default}
                  alt="footer"
                />
                <p className=" text-lg" style={{ color: "#eeeeee" }}>
                  ABN: 300 632 831 43
                </p>
                <br />
                <br />
                <Link
                  to="/login/loginpage?type=admin"
                  className="text-white text-base border border-white m-4 p-4"
                >
                  Admin Login
                </Link>
                <br />
                <br />
                <br />
                <Link
                  to="/login/loginpage?type=portal"
                  className="text-white text-base border border-white m-4 p-4"
                >
                  Staff Login
                </Link>

                {/* <Space direction="vertical">
                  <div>
                    <Follow
                      username="GALAXY_AUSTRALIA"
                      options={{ size: "large" }}
                    />
                  </div>
                  <div
                    style={{ marginLeft: "-10px" }}
                    className="fb-like"
                    data-href="https://www.facebook.com/AdvocatesForDignity"
                    data-width="200"
                    data-layout="button_count"
                    data-action="like"
                    data-size="large"
                    data-share="true"
                  ></div>
                </Space> */}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="text-left space-y-4">
                <h3
                  style={{
                    color: "#3D98C7",
                    fontWeight: 600,
                    borderBottom: "1px solid #3D98C7",
                    marginBottom: "25px",
                    lineHeight: "44px",
                  }}
                >
                  CONTACT DETAILS
                </h3>
                <p style={{ color: "#eeeeee", fontSize: "17px" }}>
                  <EnvironmentOutlined
                    style={{ fontSize: "24px", color: "#cccccc" }}
                  />
                  &nbsp; &nbsp; Level 1, 27 Queen Street <br />
                  Auburn NSW 2144 Australia
                </p>
                <p
                  style={{
                    color: "#eeeeee",
                    fontWeight: "lighter",
                    fontSize: "24px",
                  }}
                >
                  <PhoneOutlined
                    style={{ fontSize: "24px", color: "#cccccc" }}
                  />
                  <a href="tel://0280658469" style={{ color: "#eeeeee" }}>
                    &nbsp; 02 9649 3494
                  </a>
                </p>
                <p style={{ color: "#eeeeee", fontSize: "18px" }}>
                  <MailOutlined
                    style={{ fontSize: "24px", color: "#cccccc" }}
                  />
                  <a
                    href="mailto://info@aro.org.au"
                    style={{ color: "#eeeeee" }}
                  >
                    &nbsp; info@galaxyfoundation.org.au
                  </a>
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <h3
                style={{
                  color: "#3D98C7",
                  fontWeight: 600,
                  borderBottom: "1px solid #3D98C7",
                  marginBottom: "25px",
                  lineHeight: "44px",
                }}
              >
                SUBSCRIBE TO OUR NEWSLETTER
              </h3>
              <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Fullname required" }]}
                >
                  <Input
                    placeholder="Enter your fullname"
                    size="large"
                    style={{ maxWidth: "250px" }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Email required" },
                    { type: "email" },
                  ]}
                >
                  <Input
                    placeholder="Enter your email address"
                    size="large"
                    style={{ maxWidth: "250px" }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    size="large"
                  >
                    SUBSCRIBE
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
        <div className="mt-6 border-t-2  pt-8 border-sky-900">
          <p className="text-small  text-center text-gray-400">
            &copy; 2021, Galaxy Foundation . All rights reserved. Developed by
            Softmile with <HeartOutlined /> React.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default FooterWidget;
