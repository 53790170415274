import { FileOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";

export const GlobalVariables = {
  formLayout: {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  },
  tailLayout: {
    wrapperCol: {
      offset: 6,
      span: 18,
    },
  },
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  },
  formItemLayoutWithOutLabel: {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 16, offset: 8 },
    },
  },
  getMyRole: () => {
    return JSON.parse(localStorage.getItem("user")).role;
  },
  getBase64: (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  },
  uploadButton: (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  ),
  fileButton: (name) => (
    <div>
      <FileOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">{name}</div>
    </div>
  ),
  createMarkup: (gelenDetay) => {
    return {
      __html: gelenDetay,
    };
  },
  auStates: [
    {
      code: "ACT",
      name: "Australian Capital Territory",
      subdivision: null,
    },
    {
      code: "NSW",
      name: "New South Wales",
      subdivision: null,
    },
    {
      code: "NT",
      name: "Northern Territory",
      subdivision: null,
    },
    {
      code: "QLD",
      name: "Queensland",
      subdivision: null,
    },
    {
      code: "SA",
      name: "South Australia",
      subdivision: null,
    },
    {
      code: "TAS",
      name: "Tasmania",
      subdivision: null,
    },
    {
      code: "VIC",
      name: "Victoria",
      subdivision: null,
    },
    {
      code: "WA",
      name: "Western Australia",
      subdivision: null,
    },
  ],
  projectTypes: [
    { id: 1, name: "General Donation" },
    { id: 2, name: "Scholarship Donation" },
  ],
  genders: [
    { id: 1, name: "Male" },
    { id: 2, name: "Female" },
  ],
  groups: [
    { id: 1, name: "E/M" },
    { id: 2, name: "Kadro" },
  ],
  senders: [
    { id: 1, name: "Galaxy Foundation" },
    { id: 2, name: "Amity College" },
    { id: 3, name: "Aro" },
    { id: 4, name: "Isra" },
    { id: 5, name: "AFD" },
    { id: 6, name: "AUF" },
  ],
  times: [
    { id: 1, name: "7 days ago 18:00" },
    { id: 2, name: "5 days ago 18:00" },
    { id: 3, name: "3 days ago 18:00" },
    { id: 4, name: "1 day ago 18:00" },
    { id: 5, name: "3 hours ago" },
    { id: 6, name: "1 hour ago" },
    { id: 7, name: "15 minutes ago" },
    { id: 8, name: "On start" },
  ],
  compare: (a, b) => {
    var atitle = a || "";
    var btitle = b || "";
    var alfabe =
      "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
    if (atitle.length === 0 || btitle.length === 0) {
      return atitle.length - btitle.length;
    }
    for (var i = 0; i < atitle.length && i < btitle.length; i++) {
      var ai = alfabe.indexOf(atitle[i]);
      var bi = alfabe.indexOf(btitle[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  },
};
