import { CheckIcon } from "@heroicons/react/outline";
import React from "react";

const features = [
  {
    name: "Establishing schools",
  },
  {
    name: "Providing scholarships to needy students",
  },
  {
    name: "Establishing boarding houses for students",
  },
  {
    name: "Organising emergency relief and human aid campaigns for countries in need",
  },
  {
    name: "Organising annual festivals ",
  },
  {
    name: "Organising social functions",
  },
];

export default function Features() {
  return (
    <div className="bg-gradient-to-b from-white to-gray-100 border-t border-b border-gray-300 mt-2">
      <div className=" mx-auto py-16 px-4 sm:px-6 lg:py-16 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-blue-700 uppercase tracking-wide">
            About
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">
            Galaxy Foundation
          </p>
          <p className="mt-4 text-lg text-gray-500">
            Galaxy Foundation Limited is a non-profit organisation that engages
            in educational, social and charitable activities in Australia.
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <p className="my-6 text-lg text-gray-500">
            Fulfilling vital community needs since our foundation in 1994, our
            scope of work includes;
          </p>
          <dl className="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col sm:gap-x-6 sm:gap-y-4 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-base leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
              </div>
            ))}
          </dl>
          <p className="my-4 text-lg text-gray-500 font-light">
            While we will continue promoting academic excellence in Australia
            through our initiative Amity College, we will also seek for future
            social and charitable opportunities to further contribute towards
            the welfare of global societies.
          </p>
          <p className="mt-4 text-lg text-gray-500">
            Inspired by the dedication and many sacrifices of our community,
            considering the Foundation’s growth achieved over the past 15 years,
            and witnessing the positive impact of our work on many lives of the
            Australians and other nations of the world, we look forward to our
            continued engagement with our community in building a brighter
            future.
          </p>
        </div>
      </div>
    </div>
  );
}
