import { Col, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const Raporlar = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [sixMonth, setSixMonth] = useState(null);
  const [toplamRow, setToplamRow] = useState(null);

  const GetLastSixMonth = (index) => {
    let months = [];
    let monthsRequired = 6;

    for (let i = monthsRequired; i >= 1; i--) {
      months.push({
        key: i,
        month: moment().locale("tr").subtract(i, "months").format("MMMM YYYY"),
        index,
      });
    }
    return months;
  };

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.mentorReports(
        JSON.parse(localStorage.getItem("user")).id
      ).then((data) => {
        console.log(data.data);
        const toplamlar = [];
        data.data.sixmonth.mutevelliler.map((m) => {
          var ziyaret = m.raporlar.reduce((p, n) => p + n.ziyaret, 0);
          var kamp = m.raporlar.reduce((p, n) => p + n.kamp, 0);
          var mutDevam = m.raporlar.reduce((p, n) => p + n.mutDevam, 0);
          var sohbetDevam = m.raporlar.reduce((p, n) => p + n.sohbetDevam, 0);
          var aileziyareti = m.raporlar.reduce((p, n) => p + n.aileziyareti, 0);
          toplamlar.push({
            ziyaret,
            kamp,
            mutDevam,
            sohbetDevam,
            aileziyareti,
          });
        });
        setToplamRow(toplamlar);
        setSixMonth(data.data.sixmonth.mutevelliler);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Month",
      dataIndex: "month",
      width: "16%",
    },
    {
      title: "Ziyaret",
      render: (item, rec, i) =>
        sixMonth[item.index].raporlar.filter(
          (f) => moment(f.tarih).locale("tr").format("MMMM YYYY") === rec.month
        )[0]?.ziyaret,
      width: "16%",
      className: "text-center",
    },
    {
      title: "Aile Ziyaret",
      render: (item, rec, i) =>
        sixMonth[item.index].raporlar.filter(
          (f) => moment(f.tarih).locale("tr").format("MMMM YYYY") === rec.month
        )[0]?.aileziyareti,
      width: "16%",
      className: "text-center",
    },
    {
      title: "Kamp",
      render: (item, rec, i) =>
        sixMonth[item.index].raporlar.filter(
          (f) => moment(f.tarih).locale("tr").format("MMMM YYYY") === rec.month
        )[0]?.kamp,
      width: "16%",
      className: "text-center",
    },
    {
      title: "Mutevelli ",
      render: (item, rec, i) =>
        sixMonth[item.index].raporlar.filter(
          (f) => moment(f.tarih).locale("tr").format("MMMM YYYY") === rec.month
        )[0]?.mutDevam,
      width: "16%",
      className: "text-center",
    },
    {
      title: "Sohbet",
      render: (item, rec, i) =>
        sixMonth[item.index].raporlar.filter(
          (f) => moment(f.tarih).locale("tr").format("MMMM YYYY") === rec.month
        )[0]?.sohbetDevam,
      width: "16%",
      className: "text-center",
    },
  ];

  return (
    <div className="max-w-3xl overflow-x-scroll">
      {sixMonth &&
        sixMonth.map((mut, index) => (
          <div key={index}>
            <p className="text-sky-500 font-bold text-lg">
              {mut.firstname + " " + mut.lastname} kisisinin son 6 aylik raporu
            </p>
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "bg-white" : "bg-gray-50"
              }
              size="small"
              // scroll={{ x: 800 }}
              pagination={false}
              bordered
              rowKey="key"
              columns={columns}
              dataSource={GetLastSixMonth(index)}
            />
            {toplamRow && (
              <Row className="text-center p-2 w-full bg-white font-bold">
                <Col style={{ width: "16.6%" }}>Toplam</Col>
                <Col style={{ width: "16.6%" }}>{toplamRow[index].ziyaret}</Col>
                <Col style={{ width: "16.6%" }}>
                  {toplamRow[index].aileziyareti}
                </Col>
                <Col style={{ width: "16.6%" }}>{toplamRow[index].kamp}</Col>
                <Col style={{ width: "17%" }}>{toplamRow[index].mutDevam}</Col>
                <Col style={{ width: "16.6%" }}>
                  {toplamRow[index].sohbetDevam}
                </Col>
              </Row>
            )}
            <br />
            <br />
          </div>
        ))}
    </div>
  );
};

export default Raporlar;
