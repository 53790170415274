import { Col, Descriptions, Divider, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const SonAyRaporu = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getSonAyRaporu().then((data) => {
        setData(data.data);
        setTotal(data.data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Mentor",
      dataIndex: "mentor",
    },
    {
      title: "Mutevelli",
      dataIndex: "mut",
    },
  ];
  const columns2 = [
    {
      title: "Mentor",
      dataIndex: "mentor",
      sorter: (a, b) => GlobalVariables.compare(a.mentor, b.mentor),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => GlobalVariables.compare(a.gender, b.gender),
    },
    {
      title: "Mut.Sayi",
      dataIndex: "mutevelli",
      sorter: (a, b) => a.mutevelli - b.mutevelli,
    },
    {
      title: "Ziyaret",
      dataIndex: "ziyaret",
      className: "text-center",
      sorter: (a, b) => a.ziyaret - b.ziyaret,
    },
    {
      title: "Aile Ziyareti",
      dataIndex: "aileziyareti",
      className: "text-center",
      sorter: (a, b) => a.aileziyareti - b.aileziyareti,
    },
    {
      title: "Kamp",
      dataIndex: "kamp",
      className: "text-center",
      sorter: (a, b) => a.kamp - b.kamp,
    },
    {
      title: "Mutevelli",
      dataIndex: "mutDevam",
      className: "text-center",
      sorter: (a, b) => a.mutDevam - b.mutDevam,
    },
    {
      title: "Sohbet",
      dataIndex: "sohbetDevam",
      className: "text-center",
      sorter: (a, b) => a.sohbetDevam - b.sohbetDevam,
    },
  ];

  return (
    data && (
      <div>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            {total && (
              <Table
                title={() => (
                  <span className="text-base text-blue-500">
                    Toplu son ay raporu
                  </span>
                )}
                dataSource={total}
                columns={columns2}
                pagination={false}
                size="small"
                bordered
                rowKey="mentor"
              />
            )}
            <br />
            <br />
            <Descriptions
              bordered
              column={1}
              title={<p className="mt-2 ml-6 text-sky-500">Son Ay Raporu</p>}
              className="border border-gray-300 bg-white"
            >
              <Descriptions.Item label="Erkek Mutevelli">
                {data.erkekler}
              </Descriptions.Item>
              <Descriptions.Item label="Kadın Mutevelli">
                {data.bayanlar}
              </Descriptions.Item>
              <Descriptions.Item label="Ziyaret Edilmeyen Erkek">
                {data.erkekler - data.ziyaretErkek}
              </Descriptions.Item>
              <Descriptions.Item label="Ziyaret Edilmeyen Kadın">
                {data.bayanlar - data.ziyaretBayan}
              </Descriptions.Item>
              <Descriptions.Item label="Erkek Mutevelli Devamsiz">
                {data.erkekler - data.mutDevamErkek}
              </Descriptions.Item>
              <Descriptions.Item label=" Mutevelli Devamsiz">
                {data.bayanlar - data.mutDevamBayan}
              </Descriptions.Item>
              <Descriptions.Item label="Erkek Mutevelli Kamp Yapmayan">
                {data.erkekler - data.kampDevamErkek}
              </Descriptions.Item>
              <Descriptions.Item label="Kadın Mutevelli Kamp Yapmayan">
                {data.bayanlar - data.kampDevamBayan}
              </Descriptions.Item>
              <Descriptions.Item label="Erkek Aile Ziyareti Yapilmayan">
                {data.erkekler - data.aileZiyaretErkek}
              </Descriptions.Item>
              <Descriptions.Item label="Kadın Aile Ziyareti Yapilmayan">
                {data.bayanlar - data.aileZiyaretBayan}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Divider orientation="left">Ziyaret Edilmeyen Erkekler</Divider>
            <Table
              dataSource={data.ziyaretEdilmeyenErkekler}
              columns={columns}
              pagination={false}
              size="small"
              rowKey="id"
              loading={data === null}
            />
            <Divider orientation="left">Ziyaret Edilmeyen Kadinlar</Divider>
            <Table
              dataSource={data.ziyaretEdilmeyenKadinlar}
              columns={columns}
              pagination={false}
              size="small"
              rowKey="id"
              loading={data === null}
            />
            <Divider orientation="left">Kampa Gelmeyen Erkekler</Divider>
            <Table
              dataSource={data.kampaGelmeyenErkekler}
              columns={columns}
              pagination={false}
              size="small"
              rowKey="id"
              loading={data === null}
            />

            <Divider orientation="left">Kampa Gelmeyen Kadinlar</Divider>
            <Table
              dataSource={data.kampaGelmeyenKadinlar}
              columns={columns}
              pagination={false}
              size="small"
              rowKey="id"
              loading={data === null}
            />
            <Divider orientation="left">
              Mutevelliye Gelmeyen Erkekler (aylik 1 yada 0)
            </Divider>
            <Table
              dataSource={data.mutGelmeyenErkekler}
              columns={columns}
              pagination={false}
              size="small"
              rowKey="id"
              loading={data === null}
            />

            <Divider orientation="left">
              Mutevelliye Gelmeyen Kadinlar (aylik 1 yada 0)
            </Divider>
            <Table
              dataSource={data.mutGelmeyenKadinlar}
              columns={columns}
              pagination={false}
              size="small"
              rowKey="id"
              loading={data === null}
            />
          </Col>
        </Row>
      </div>
    )
  );
};

export default SonAyRaporu;
