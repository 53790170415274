import { DeleteOutlined, MailOutlined } from "@ant-design/icons";
import {
  Select,
  Table,
  Form,
  Input,
  Button,
  Popconfirm,
  notification,
  Tooltip,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { AdminService } from "../../../resources/AdminService";
import { GlobalVariables } from "../../../resources/GlobalVariables";

const Registrations = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [events, setEvents] = useState(null);
  const [regs, setRegs] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [quillValue, setQuillValue] = useState("");
  const [form] = Form.useForm();
  const { lg } = useBreakpoint();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getEventsList().then((data) => {
        setEvents(data.data);
      });
    }
  }, [initialRender]);
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const eventSelected = (e) => {
    setDefaultValues(null);
    form.resetFields();
    setQuillValue("");
    AdminService.getEventRegs(e).then((data) => {
      setRegs(data.data);
      if (data.data.length > 0) {
        setDefaultValues({
          zoom: data.data[0].zoom,
          youtube: data.data[0].youtube,
          meet: data.data[0].meet,
        });
      }
    });
  };

  const deleteRegs = (id) => {
    AdminService.deleteRegistrations(id).then((data) => {
      const temp = regs.filter((r) => r.reg.id !== id);
      notification.success({
        message: "Success",
        description: "Registration deleted!",
      });
      setRegs(temp);
    });
  };

  const columns = [
    {
      title: "No",
      render: (a, b, c) => c + 1,
    },
    {
      title: "Registration Date",
      dataIndex: "reg",
      render: (reg) => (
        <span>{moment(reg.createdOn).format("DD MMM dddd, HH:mm")}</span>
      ),
    },
    {
      title: "Name",
      dataIndex: "reg",
      render: (reg) => <span>{reg.name}</span>,
    },
    {
      title: "Email",
      dataIndex: "reg",
      render: (reg) => <span>{reg.email}</span>,
    },
    {
      title: "Message",
      dataIndex: "reg",
      render: (reg) => <span>{reg.message}</span>,
    },
    {
      title: "Adult",
      dataIndex: "reg",
      render: (reg) => <span>{reg.adult}</span>,
    },
    {
      title: "Child",
      dataIndex: "reg",
      render: (reg) => <span>{reg.child}</span>,
    },
    {
      title: "Actions",
      dataIndex: "reg",
      render: (reg) => (
        <Tooltip title="Delete Registration" placement="left" key={reg.id}>
          <Popconfirm
            title="Are you sure ?"
            onConfirm={() => deleteRegs(reg.id)}
            okText="Yes! Delete."
            cancelText="Cancel"
          >
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              size="small"
            />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  const onFinish = (values) => {
    const req = {
      eventsId: regs[0].reg.eventsId,
      message: quillValue,
      ...form.getFieldsValue(),
    };
    AdminService.sendEmailToRegs(req).then((data) => {
      notification.success({
        message: "Success",
        description: "Messages sent successfully!",
      });
      setDefaultValues(null);
      form.resetFields();
      setQuillValue("");
    });
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const quillChange = (e) => {
    setQuillValue(e);
  };
  return (
    <div>
      <Select
        style={{ minWidth: "300px" }}
        onChange={eventSelected}
        loading={events === null}
        placeholder="Please select event"
      >
        {events &&
          events.map((e) => (
            <Select.Option key={e.key} value={e.id}>
              {e.title}
            </Select.Option>
          ))}
      </Select>
      <br />
      <br />

      {regs && (
        <div>
          <p className="text-lg text-red-500 font-bold">Event Registrations</p>
          <Table
            dataSource={regs}
            bordered
            size="small"
            columns={columns}
            footer={() => "Total " + regs.length + " registrations"}
          />
          <br />
          <br />
          {regs.length > 0 && (
            <Form
              className="max-w-4xl"
              form={form}
              layout={lg ? "horizontal" : "vertical"}
              onFinish={onFinish}
              initialValues={{
                zoom: regs[0].zoom,
                meet: regs[0].meet,
                youtube: regs[0].youtube,
              }}
              {...GlobalVariables.formLayout}
            >
              <Form.Item label="Mail Message">
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  onChange={(e) => quillChange(e)}
                  placeholder="Type news long details"
                />
              </Form.Item>
              <Form.Item label="Zoom Link" name="zoom">
                <Input />
              </Form.Item>
              <Form.Item label="Youtube Link" name="youtube">
                <Input />
              </Form.Item>
              <Form.Item label="Meet Link" name="meet">
                <Input />
              </Form.Item>
              <Form.Item {...GlobalVariables.tailLayout}>
                <Popconfirm
                  title="Are you sure ?"
                  onConfirm={onFinish}
                  okText="Yes! Send."
                  cancelText="Cancel"
                >
                  <Button type="primary" danger icon={<MailOutlined />}>
                    Send Email to All Registrations
                  </Button>
                </Popconfirm>
              </Form.Item>
            </Form>
          )}
        </div>
      )}
    </div>
  );
};

export default Registrations;
