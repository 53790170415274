import { Carousel, Image } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

const SliderBar = ({ slides }) => {
  const router = useHistory();
  return (
    slides &&
    slides.length > 0 && (
      <div className="container mx-auto">
        <Carousel autoplay>
          {slides.map((slide) => (
            <div
              key={slide.id}
              onClick={() =>
                slide.url.substring(0, 4) === "http"
                  ? window.open(slide.url, "_blank")
                  : router.push(slide.url)
              }
              className="cursor-pointer"
            >
              <Image
                preview={false}
                src={process.env.REACT_APP_FILES_BASEURL + slide.image}
              />
            </div>
          ))}
        </Carousel>
      </div>
    )
  );
};
export default SliderBar;
