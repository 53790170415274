import { Col, Row, Image, Divider } from "antd";
import React from "react";

const SpecsBanner = () => {
  return (
    <div className="bg-f5f5f5 border-t border-b border-gray-300 ">
      <div className="container mx-auto max-w-6xl  my-10">
        <Divider orientation="left">
          <p className="text-center font-bold text-3xl text-sky-500">
            Our Organizations
          </p>
        </Divider>
        <Row gutter={[16, 16]} align="middle" justify="center">
          <Col xs={12} sm={6} md={6} lg={6} xl={6}>
            <Image
              style={{
                border: "1px solid #ccc",
                boxShadow: "0 0 10px #eee",
                backgroundColor: "white",
              }}
              preview={false}
              src={require("../../assets/images/affinity.png").default}
              width="100%"
            />
          </Col>

          <Col xs={12} sm={6} md={6} lg={6} xl={6}>
            <Image
              style={{
                border: "1px solid #ccc",
                boxShadow: "0 0 10px #eee",
                backgroundColor: "white",
              }}
              preview={false}
              src={require("../../assets/images/lbs.png").default}
              width="100%"
            />
          </Col>

          <Col xs={12} sm={6} md={6} lg={6} xl={6}>
            <Image
              style={{
                border: "1px solid #ccc",
                boxShadow: "0 0 10px #eee",
                backgroundColor: "white",
              }}
              preview={false}
              src={require("../../assets/images/gfwb.png").default}
              width="100%"
            />
          </Col>

          <Col xs={12} sm={6} md={6} lg={6} xl={6}>
            <Image
              style={{
                border: "1px solid #ccc",
                boxShadow: "0 0 10px #eee",
                backgroundColor: "white",
              }}
              preview={false}
              src={require("../../assets/images/gpc.png").default}
              width="100%"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SpecsBanner;
