import { ClearOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Space,
  Table,
  notification,
} from "antd";
import locale from "antd/es/date-picker/locale/tr_TR";
import moment from "moment";
import "moment/locale/tr";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
const AylikRaporGirisi = () => {
  const [secilenTarih, setSecilenTarih] = useState(null);
  const [secilenMutevelli, setSecilenMutevelli] = useState(null);
  const [muts, setMuts] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [form] = Form.useForm();
  const [tetik, setTetik] = useState(0);

  useEffect(() => {
    if (secilenTarih !== null) {
      console.log("effect", secilenTarih.format());
      const values = {
        mentorId: JSON.parse(localStorage.getItem("user")).id,
        date: secilenTarih.format(),
      };
      AdminService.getRaporlar(values).then((data) => {
        console.log("aa", data.data);
        setMuts(data.data);
      });
    }
  }, [secilenTarih, tetik]);

  const tarihSecildi = (tarih) => {
    console.log(tarih);
    setSecilenTarih(tarih);
  };

  const columns = [
    {
      title: "Ad Soyad",
      render: (item) => <span>{item.firstname + " " + item.lastname}</span>,
    },
    {
      title: "Cinsiyet",
      render: (item) => (
        <span>{item.gender === "Male" ? "Erkek" : "Kadın"}</span>
      ),
    },
    {
      title: "Ziyaret",
      render: (item) => <span>{item.reports?.ziyaret}</span>,
      onCell: (text, record) => {
        const checkCase = text.reports?.ziyaret === 0 ? "lightpink" : "white";
        return {
          style: { background: checkCase },
          className: "text-center",
        };
      },
    },
    {
      title: "Aile Ziyareti",
      render: (item) => <span>{item.reports?.aileziyareti}</span>,
      className: "text-center",
    },
    {
      title: "Kamp",
      render: (item) => <span>{item.reports?.kamp}</span>,
      className: "text-center",
      onCell: (text, record) => {
        const checkCase = text.reports?.kamp === 0 ? "lightpink" : "white";
        return {
          style: { background: checkCase },
          className: "text-center",
        };
      },
    },
    {
      title: "Mut.Devam",
      render: (item) => <span>{item.reports?.mutDevam}</span>,

      onCell: (text, record) => {
        const checkCase =
          text.reports?.mutDevam === 0
            ? "lightpink"
            : text.reports?.mutDevam === 1
            ? "NavajoWhite"
            : "white";
        return {
          style: { background: checkCase },
          className: "text-center",
        };
      },
    },
    {
      title: "Sohbet Devam",
      render: (item) => <span>{item.reports?.sohbetDevam}</span>,
      className: "text-center",
      onCell: (text, record) => {
        const checkCase =
          text.reports?.sohbetDevam === 0
            ? "lightpink"
            : text.reports?.sohbetDevam === 1
            ? "NavajoWhite"
            : "white";
        return {
          style: { background: checkCase },
          className: "text-center",
        };
      },
    },
    {
      title: "Aksiyon",
      render: (item) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            type="primary"
            size="small"
            onClick={() => {
              form.resetFields();
              setFormVisible(true);
              setSecilenMutevelli(item);
              console.log(item);
              form.setFieldsValue(item.reports);
            }}
          >
            Guncelle
          </Button>
          <Button
            icon={<ClearOutlined />}
            type="primary"
            danger
            size="small"
            onClick={() => form.resetFields()}
          >
            Temizle
          </Button>
        </Space>
      ),
    },
  ];

  const onFinish = (values) => {
    values.kamp = values.kamp === undefined ? 0 : values.kamp;
    values.aileziyareti =
      values.aileziyareti === undefined ? 0 : values.aileziyareti;
    values.sohbetDevam =
      values.sohbetDevam === undefined ? 0 : values.sohbetDevam;
    values.ziyaret = values.ziyaret === undefined ? 0 : values.ziyaret;
    values.mutDevam = values.mutDevam === undefined ? 0 : values.mutDevam;

    values = {
      ...values,
      mutId: secilenMutevelli.id,
      mentorId: JSON.parse(localStorage.getItem("user")).id,
      tarih: secilenTarih.format(),
    };
    console.log(values);
    AdminService.newReport(values).then((data) => {
      setTetik(tetik + 1);
      form.resetFields();
      setFormVisible(false);
      notification.success({ message: "Rapor kaydedildi" });
    });
  };
  function disabledDate(current) {
    // Can not select days before today and today
    return current > moment().endOf("month");
  }
  return (
    <div className="max-w-4xl">
      <Space direction="horizontal">
        <span>Ay Seciniz : </span>
        <DatePicker
          disabledDate={disabledDate}
          picker="month"
          format="MMMM YY"
          locale={locale}
          onSelect={tarihSecildi}
        />
      </Space>

      <br />
      <br />
      {secilenTarih && (
        <Table
          title={() => (
            <span>{moment(secilenTarih).format("MM/YYYY")} Aylik Raporu</span>
          )}
          dataSource={muts}
          loading={muts === null}
          size="small"
          bordered
          columns={columns}
          rowKey="id"
          pagination={false}
          scroll={{ x: 800 }}
        />
      )}

      {formVisible && (
        <>
          <Divider orientation="left">
            <span className="text-sky-500 mt-6">
              {secilenMutevelli.firstname + " " + secilenMutevelli.lastname}{" "}
              aylik rapor girisi
            </span>
          </Divider>
          <Form {...GlobalVariables.formLayout} onFinish={onFinish} form={form}>
            <Form.Item label="ID" name="id">
              <InputNumber disabled />
            </Form.Item>
            <Form.Item label="Ziyaret" name="ziyaret">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Aile Ziyareti" name="aileziyareti">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Kamp" name="kamp">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Mut.Devam" name="mutDevam">
              <InputNumber />
            </Form.Item>
            <Form.Item label="Sohbet Devam" name="sohbetDevam">
              <InputNumber />
            </Form.Item>
            <Form.Item {...GlobalVariables.tailLayout}>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Guncelle
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};

export default AylikRaporGirisi;
