import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const ShareLinks = ({ shareUrl, title = "Please read this" }) => {
  return (
    <div>
      <span style={{ lineHeight: "33px", fontWeight: "bold", color: "#555" }}>
        Share this page with your friends
      </span>
      <br />
      <div className="Demo__some-network">
        <FacebookShareButton url={shareUrl} quote={title}>
          <FacebookIcon size={32} />
        </FacebookShareButton>
      </div>
      <div className="Demo__some-network">
        <FacebookMessengerShareButton url={shareUrl} appId="521270401588372">
          <FacebookMessengerIcon size={32} />
        </FacebookMessengerShareButton>
      </div>
      <div className="Demo__some-network">
        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon size={32} />
        </TwitterShareButton>
      </div>
      <div className="Demo__some-network">
        <TelegramShareButton url={shareUrl} title={title}>
          <TelegramIcon size={32} />
        </TelegramShareButton>
      </div>
      <div className="Demo__some-network">
        <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
          <WhatsappIcon size={32} />
        </WhatsappShareButton>
      </div>
      <div className="Demo__some-network">
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={32} />
        </LinkedinShareButton>
      </div>
      <div className="Demo__some-network">
        <EmailShareButton url={shareUrl} subject={title} body="body">
          <EmailIcon size={32} />
        </EmailShareButton>
      </div>
      <div className="Demo__some-network">
        <ViberShareButton url={shareUrl} title={title}>
          <ViberIcon size={32} />
        </ViberShareButton>
      </div>
    </div>
  );
};

export default ShareLinks;
