import { ArrowLeftOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Image, Modal, Row, Space } from "antd";
import Countdown from "antd/lib/statistic/Countdown";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AdminService } from "../../../resources/AdminService";
import RegForm from "../../home/RegForm";
import ShareLinks from "../../home/ShareLinks";

const EventDetails = () => {
  // @ts-ignore
  const location = useLocation();
  const router = useHistory();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  function createMarkup(gelenDetay) {
    return {
      __html: gelenDetay,
    };
  }
  // @ts-ignore
  const { veri } = location.state || { veri: null };

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      if (veri === null) {
        AdminService.getEventById(id).then((data) => {
          setItem({ events: data.data });
        });
      } else {
        setItem(veri);
      }
    }
  }, [id, initialRender, veri]);
  function daysRemaining(date) {
    var eventdate = moment(date);
    var todaysdate = moment();
    return eventdate.diff(todaysdate, "days");
  }
  return (
    item && (
      <div className="overflow-hidden container mx-auto max-w-4xl px-2 py-10">
        <Modal
          visible={modalVisible}
          style={{ top: 20 }}
          title={"Register for " + item.events.title}
          closable
          onCancel={() => setModalVisible(false)}
          footer={null}
        >
          <RegForm
            smv={setModalVisible}
            eventId={item.events.id}
            isCount={item.events.isCount}
            isMessage={item.events.isMessage}
            regMessage={item.events.regMessage}
          />
        </Modal>
        <Space wrap>
          <Button
            onClick={() => router.goBack()}
            icon={<ArrowLeftOutlined />}
            type="primary"
            ghost
          >
            Go Back
          </Button>
          {moment(item.events.date) > moment(Date.now()) && (
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={() => setModalVisible(true)}
            >
              Please Register
            </Button>
          )}
          <Button>{moment(item.events.date).format("DD-MMM-YY HH:mm")}</Button>
        </Space>
        <br />
        <br />
        {moment(item.events.date) > moment(Date.now()) && (
          <>
            {" "}
            Remaining time :&nbsp;
            {daysRemaining(item.events.date) < 3 ? (
              <Countdown value={moment(item.events.date)} format="HH:mm:ss" />
            ) : (
              <p className="text-xl">
                {daysRemaining(item.events.date) + " days"}
              </p>
            )}
          </>
        )}
        <p className="font-extrabold text-2xl text-blue-600">
          {item.events.title}
        </p>
        <p className="text-lg text-gray-500">{item.events.description}</p>
        {item.events.images.map(
          (i) =>
            i.isTop && (
              <Image
                key={i.id}
                preview={false}
                style={{
                  maxWidth: "800px",
                  border: "3px solid #fff",
                  boxShadow: "0 0 5px #eee",
                }}
                src={process.env.REACT_APP_FILES_BASEURL + i.url}
                alt=""
              />
            )
        )}
        <br />
        <br />
        <Row align="middle" gutter={[10, 10]}>
          {item.events.videos.map(
            (video) =>
              video.isTop && (
                <Col
                  key={video.id}
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="player-wrapper">
                    <ReactPlayer
                      url={video.url}
                      className="react-player"
                      width="100%"
                      height="100%"
                      controls={true}
                    />
                  </div>
                </Col>
              )
          )}
        </Row>
        <br />
        <br />
        <div
          dangerouslySetInnerHTML={createMarkup(item.events.details)}
          className="booker"
        />
        <br />
        {item.events.images.length > 1 && (
          <div className="max-w-3xl">
            <Divider orientation="left">Image Gallery</Divider>
            <Image.PreviewGroup>
              <Row align="middle" gutter={[10, 10]}>
                {item.events.images.map(
                  (imaj) =>
                    !imaj.isTop && (
                      <Col key={imaj.id}>
                        <Image
                          className="gallery-image"
                          width={200}
                          src={process.env.REACT_APP_FILES_BASEURL + imaj.url}
                        />
                      </Col>
                    )
                )}
              </Row>
            </Image.PreviewGroup>
          </div>
        )}
        {item.events.videos.length > 0 && (
          <div className="max-w-3xl">
            <Divider />

            <Row align="middle" gutter={[10, 10]}>
              {item.events.videos.map(
                (video) =>
                  !video.isTop && (
                    <Col
                      key={video.id}
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className="player-wrapper">
                        <ReactPlayer
                          url={video.url}
                          className="react-player"
                          width="100%"
                          height="100%"
                          controls={true}
                        />
                      </div>
                    </Col>
                  )
              )}
            </Row>
          </div>
        )}
        <br />
        {moment(item.events.date) > moment(Date.now()) && (
          <Button
            type="primary"
            icon={<CheckOutlined />}
            onClick={() => setModalVisible(true)}
          >
            Please Register
          </Button>
        )}
        <br />
        <br />
        <ShareLinks
          shareUrl={window.location.href}
          title="Please read this event details of the Galaxy Foundation"
        />
      </div>
    )
  );
};

export default EventDetails;
