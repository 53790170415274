import { SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Descriptions,
  Form,
  Grid,
  Input,
  InputNumber,
  notification,
  Row,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import Medine from "../../assets/images/mm-banner.jpg";
import MedineMobile from "../../assets/images/mmm-banner.jpeg";
import SalavatImage from "../../assets/images/salavat.jpg";

const Salavat = () => {
  const { lg } = Grid.useBreakpoint();
  const [read, setRead] = useState(0);
  const [form] = Form.useForm();
  const [liste, setListe] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getSalavats().then((data) => {
        console.log(data.data);
        setRead(data.data.count);
        setListe(data.data.liste);
      });
    }
  }, [initialRender]);
  const onFinish = (v) => {
    console.log(v);
    AdminService.newSalavats(v).then((data) => {
      notification.success({
        message: "Success!",
        description: "Your reads submitted succesfully. Thank you.",
      });
      form.resetFields();
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "createdOn",
      render: (createdOn) => moment(createdOn).format("dddd, DD-MMM-YY"),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Number of reads",
      dataIndex: "sayi",
    },
  ];
  return (
    <div>
      <div className="text-center  min-h-screen">
        <img
          src={lg ? Medine : MedineMobile}
          alt="Medine i Munevvere"
          className="w-full content-center object-cover h-[400px]"
        />
        <Row gutter={0}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="bg-[#DFD5BE] "
          >
            <div className="text-4xl text-[#237039] lg:text-6xl font-light mt-4 lg:mt-16 ">
              <p>
                We are reading <br />
                <b>3.000.000</b>
                <br /> salawat
              </p>
              <br />
              <Descriptions
                bordered
                column={1}
                className="m-2 lg:m-6 font-normal shadow-lg bg-[#237039]"
              >
                <Descriptions.Item
                  labelStyle={{
                    backgroundColor: "transparent",
                  }}
                  label={
                    <span className="text-2xl text-[#fff] lg:text-3xl">
                      Reads
                    </span>
                  }
                >
                  <span className="text-2xl text-[#fff] lg:text-5xl font-semibold">
                    {read}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item
                  labelStyle={{ backgroundColor: "transparent" }}
                  label={
                    <span className="text-2xl text-[#fff] lg:text-3xl">
                      Left
                    </span>
                  }
                >
                  <span className="text-2xl text-[#fff] lg:text-5xl font-semibold">
                    {3000000 - read}
                  </span>
                </Descriptions.Item>
              </Descriptions>
              <br />
              <p className="mx-2 mb-4 lg:mx-4 ">Would you like to join too?</p>
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="bg-[#DFD5BE] "
          >
            <img src={SalavatImage} alt="Salavat" className="w-full" />
          </Col>
        </Row>
      </div>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="bg-[#DFD5BE] pb-16"
        >
          <div className="max-w-lg lg:mx-auto bg-[#f8f9fa] border border-gray-200 p-2 mx-2 lg:p-4 shadow-xl">
            <p className="text-sky-500 text-base mb-2">New Reads Form</p>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item name="name" label="Name">
                <Input placeholder="Type your name" />
              </Form.Item>
              <Form.Item name="reads" label="Number of reads">
                <InputNumber
                  placeholder="Type your number of reads"
                  className="w-full"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  icon={<SaveOutlined />}
                >
                  Send
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="bg-[#DFD5BE]  lg:pr-4 px-2 -mt-6 lg:mt-0"
        >
          <Table
            title={() => (
              <span className="text-sky-500 text-base">Last Reads</span>
            )}
            columns={columns}
            size="small"
            pagination={{ pageSize: 50 }}
            dataSource={liste}
            rowKey="id"
            bordered
            loading={liste === null}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Salavat;
