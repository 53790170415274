import { DeleteOutlined, ManOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

const NewMutevelli = () => {
  const [bloading, setBloading] = useState(false);
  const router = useHistory();
  //const [phone, setPhone] = useState(null);
  const [form] = Form.useForm();
  const [muts, setMuts] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getMutevellis(
        JSON.parse(localStorage.getItem("user")).id
      ).then((data) => {
        setMuts(data.data);
      });
    }
  }, [initialRender]);
  const onFinish = (values) => {
    console.log(values);
    values = {
      ...values,
      mentorId: JSON.parse(localStorage.getItem("user")).id,
    };
    AdminService.NewMutevelli(values).then((data) => {
      notification.success({
        message: "Success",
        description: "Mutevelli eklendi",
      });
      onReset();
      setInitialRender(true);
      setBloading(false);
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const deleteMutevelli = (id) => {
    AdminService.deleteMutevelli(id).then((data) => {
      setInitialRender(true);
      notification.success({ message: "Silindi." });
    });
  };
  const columns = [
    {
      title: "Ad",
      dataIndex: "firstname",
    },
    {
      title: "Soyad",
      dataIndex: "lastname",
    },
    {
      title: "Cinsiyet",
      dataIndex: "gender",
    },
    {
      title: "Aksiyon",
      render: (item) => (
        <Tooltip title="Delete Alert" placement="left">
          <Popconfirm
            title="Emin misiniz ?"
            onConfirm={() => deleteMutevelli(item.id)}
            okText="Evet! Sil."
            cancelText="Iptal"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <div className="m-4 p-4 border border-gray-300 max-w-xl mx-auto bg-gray-100">
            <Divider orientation="left">Yeni Mutevelli Formu</Divider>
            <Form onFinish={onFinish} onReset={onReset} form={form}>
              <Form.Item
                name="firstname"
                rules={[{ required: true, message: "Gerekli!" }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Mutevelli ad"
                />
              </Form.Item>
              <Form.Item
                name="lastname"
                rules={[{ required: true, message: "Gerekli!" }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Mutevelli soyad"
                />
              </Form.Item>
              <Form.Item
                name="genderId"
                rules={[{ required: true, message: "Gerekli!" }]}
              >
                <Select
                  placeholder={
                    <span>
                      <ManOutlined /> Cinsiyet seciniz
                    </span>
                  }
                >
                  <Select.Option key={24} value={1}>
                    Erkek
                  </Select.Option>
                  <Select.Option key={12} value={2}>
                    Kadın
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  loading={bloading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Table
            rowKey="id"
            pagination={false}
            loading={muts === null}
            size="small"
            dataSource={muts}
            columns={columns}
            bordered
          />
        </Col>
      </Row>
    </div>
  );
};

export default NewMutevelli;
