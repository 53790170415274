import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Col, Divider, Image, PageHeader, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import { useHistory, useParams } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import DonationForm from "./DonationForm";

const ProjectDetails = () => {
  // @ts-ignore
  const { id } = useParams();
  const [initialRender, setInitialRender] = useState(true);
  const [veri, setVeri] = useState(null);
  const stripePromiseGeneral = useMemo(
    () =>
      loadStripe(
        "pk_live_51K4fpIJVBRBDOhVwSbKYZdtUEWd76m7VNsov7oM4RRTszSNz0zu8RCfDKsh8hpXQ6VkaqDilnY1tHcHXMV9Ks0qF00X1YrwHvv"
        // "pk_test_51JBXTjE8cXeCmmwbFKaTWIqMXshpGqkklyiaTWUvebeZrdZc999fblpssMaDamjk8FtMayhuzkVzg0G3GRUFAVob007gYJ4aI6"
      ),
    []
  );
  const stripePromiseScholarship = useMemo(
    () =>
      loadStripe(
        "pk_live_51K4fpIJVBRBDOhVwSbKYZdtUEWd76m7VNsov7oM4RRTszSNz0zu8RCfDKsh8hpXQ6VkaqDilnY1tHcHXMV9Ks0qF00X1YrwHvv"
        //  "pk_test_51JBXTjE8cXeCmmwbFKaTWIqMXshpGqkklyiaTWUvebeZrdZc999fblpssMaDamjk8FtMayhuzkVzg0G3GRUFAVob007gYJ4aI6"
      ),
    []
  );
  useEffect(() => {
    if (initialRender) {
      window.scrollTo(0, 0);
      setInitialRender(false);
      AdminService.getProjectById(id).then((data) => {
        setVeri(data.data);
      });
    }
  }, [id, initialRender]);

  const router = useHistory();
  function createMarkup(gelenDetay) {
    return {
      __html: gelenDetay,
    };
  }
  return (
    <div className="container lg:p-6 p-2">
      {veri && (
        <>
          <PageHeader
            className="site-page-header"
            onBack={() => router.goBack()}
            title={veri.project.name}
          />

          <Row gutter={[26, 26]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="space-y-6">
                <Image
                  preview={false}
                  className="imaj"
                  src={
                    process.env.REACT_APP_FILES_BASEURL +
                    veri.project.images[0].url
                  }
                  alt=""
                />
                <br />

                <div
                  dangerouslySetInnerHTML={createMarkup(veri.project.details)}
                />
                <br />
                {veri.project.images.length > 1 && (
                  <>
                    <Divider orientation="left">Image Gallery</Divider>
                    <Image.PreviewGroup>
                      <Row align="middle" gutter={[10, 10]}>
                        {veri.project.images.map((imaj) => (
                          <Col key={imaj.id}>
                            <Image
                              className="gallery-image"
                              width={200}
                              src={
                                process.env.REACT_APP_FILES_BASEURL + imaj.url
                              }
                            />
                          </Col>
                        ))}
                      </Row>
                    </Image.PreviewGroup>
                  </>
                )}
                {veri.project.videos.length > 0 && (
                  <>
                    <Divider orientation="left">Video Gallery</Divider>

                    <Row align="middle" gutter={[10, 10]}>
                      {veri.project.videos.map((video) => (
                        <Col
                          style={{
                            width: "100%",
                          }}
                          key={video.id}
                        >
                          <div className="player-wrapper">
                            <ReactPlayer
                              url={video.url}
                              className="react-player"
                              width="100%"
                              height="100%"
                              controls={true}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </>
                )}
                <br />
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              {/* <DonationsForm activeId={veri.project.id} /> */}

              <Elements
                stripe={
                  veri.project.pType === 1
                    ? stripePromiseGeneral
                    : stripePromiseScholarship
                }
              >
                <DonationForm pid={veri.project.id} />
              </Elements>
            </Col>
          </Row>

          <br />
          <br />
          <br />
        </>
      )}
    </div>
  );
};

export default ProjectDetails;
